import {
	CheckOutlined,
	DeleteOutlined,
	InboxOutlined,
	MoreOutlined,
	PaperClipOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import useFiles from "../Hooks/useFiles";
import { collection, getCountFromServer, onSnapshot, serverTimestamp } from "firebase/firestore";
import { Input, Popover, Space } from "antd";
import { ToastContainer } from "react-toastify";
import { db } from "../../../app/firebase";
import ProductCard from "../Components/ProductCard";

const Shop = () => {
	const [adding, setAdding] = useState(false);
	const [uploadingData, setUploadingData] = useState({
		name: "",
		price: "",
		createdAt: serverTimestamp(),
		files: [],
	});
	const [availableProducts, setAvailableProducts] = useState([]);
	const { sendToShop, deleteShopFile, progress, uploaded } = useFiles("shop");
	const [shopStatus, setShopStatus] = useState({ available: 0, sold: 0, ordered: 0 });

	useEffect(() => {
		const unsubscribeFiles = onSnapshot(collection(db, "shop"), (snapshot) => {
			// const updatedDocuments = snapshot.docs.map((doc) => doc.data());
			setAvailableProducts(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
		});

		return () => {
			unsubscribeFiles();
		};
	}, []);

	useEffect(() => {
		const getCount = async (role) => {
			const q = collection(db, "shop");

			const snapshot = await getCountFromServer(q);

			setShopStatus((prev) => ({ ...prev, available: snapshot.data().count }));
		};
		getCount();
	}, []);

	return (
		<div>
			<div className="shadow-sm flex bg-white justify-between xs:justify-center xs:gap-3 flex-wrap p-3 rounded-md">
				<div className="flex items-center gap-8 xs:gap-3 flex-wrap">
					<h3 className="text-[#fa8c17] text-lg font-medium">Overview</h3>
					<div className="text-left">
						<p className="font-semibold text-xl leading-5">{shopStatus.available}</p>
						<p className="text-[#474747]">Available</p>
					</div>
					<div className="text-left">
						<p className="font-semibold text-xl leading-5">{shopStatus.sold}</p>
						<p className="text-[#474747]">Sold</p>
					</div>
					<div className="text-left">
						<p className="font-semibold text-xl leading-5">{shopStatus.ordered}</p>
						<p className="text-[#474747]">Ordered</p>
					</div>
				</div>
				<button
					onClick={() => setAdding(!adding)}
					className="transition-all hover:bg-[#19c258] hover:text-white text-green-600 border border-green-600 p-3 rounded-xl text-sm font-medium">
					Add product
				</button>
			</div>
			<div
				className={`${
					adding ? "" : "hidden"
				} flex flex-col items-center gap-3 my-1 rounded-md bg-white shadow-sm py-[10px] px-[5px]`}>
				<Space
					direction="vertical"
					style={{
						width: "100%",
						maxWidth: "400px",
					}}>
					<Input
						onChange={(e) => setUploadingData((prev) => ({ ...prev, name: e.target.value }))}
						value={uploadingData.name}
						status="warning"
						placeholder="Name of the product"
					/>
					<Input
						onChange={(e) => setUploadingData((prev) => ({ ...prev, price: e.target.value }))}
						value={uploadingData.price}
						status="warning"
						placeholder="Price"
						type="number"
					/>
				</Space>
				<div className="w-full max-w-[400px]">
					<label
						htmlFor="shop"
						className="rounded-lg cursor-pointer flex flex-col justify-center items-center p-1 h-fit bg-[#e8eefe]">
						<p className="ant-upload-drag-icon">
							<InboxOutlined className="text-3xl" />
						</p>
						<p className="ant-upload-text">Click or drag file to this area to upload</p>
						<input
							onChange={(e) => setUploadingData((prev) => ({ ...prev, files: e.target.files }))}
							accept="image/jpeg,image/png,image/gif"
							multiple
							className="hidden"
							type="file"
							name="shop"
							id="shop"
						/>
					</label>
					<div className="flex flex-col gap-1 my-3">
						{Object.values(uploadingData.files).map((item) => {
							let url = URL.createObjectURL(item);
							const foundObj = uploaded.find((el) => el.fileName === item.name);
							return (
								<div
									className={`${
										uploaded && foundObj && foundObj.progress === 100 ? "bg-[#b2ffb2]" : "bg-[#e8eeff]"
									} rounded-lg`}>
									<div className="flex flex-row items-center justify-between gap-2">
										<div className="flex items-center gap-1">
											<img src={url} className="w-16 h-16 p-1 object-contain overflow-hidden" alt="" />
											<p className="break-all">{item.name}</p>
										</div>
										<div className="flex gap-1 pr-2">
											{uploaded && foundObj && (
												<CheckOutlined
													className={`${
														uploaded && foundObj && foundObj.progress === 100 && "text-base text-green-700"
													}`}
												/>
											)}
											{foundObj ? (
												<DeleteOutlined
													onClick={() => deleteShopFile(item.name, foundObj.id, setUploadingData)}
													className="cursor-pointer p-[3px] hover:bg-[#bcceff] rounded-[5px]"
												/>
											) : (
												<DeleteOutlined
													onClick={() => deleteShopFile(item.name, null, setUploadingData)}
													className="cursor-pointer p-[3px] hover:bg-[#bcceff] rounded-[5px]"
												/>
											)}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<button
					className="max-w-[300px] bg-[#009c3c] text-white w-full p-2 rounded-md"
					onClick={() =>
						sendToShop(uploadingData, "Document saved in database successfully", setUploadingData)
					}>
					Save
				</button>
			</div>
			<div>
				<h3 className="text-xl font-medium m-3">Products</h3>
				<div className="flex flex-wrap gap-2">
					{availableProducts.map((product, index) => (
						<ProductCard
							id={product.id}
							imgURL={product.imgURL}
							name={product.name}
							price={product.price}
							createdAt={product.createdAt}
						/>
					))}
				</div>
			</div>
			<ToastContainer />
		</div>
	);
};

export default Shop;
