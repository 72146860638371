import React, { useEffect, useState } from "react";
import { Button, DatePicker, Input, Select, Space } from "antd";
import {
	Timestamp,
	addDoc,
	collection,
	getDocs,
	query,
	serverTimestamp,
	where,
} from "firebase/firestore";
import { db } from "../../../app/firebase";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

function Groups() {
	const language = useSelector((state) => state.language.data);
	const [newGroup, setNewGroup] = useState({
		group_name: "",
		course: "",
		teacher_id: [],
		start_date: "",
		end_date: "",
		schedule: "",
		students: [],
	});
	const [users, setUsers] = useState([]);
	const [students, setStudents] = useState([]);
	const [staff, setStaff] = useState([]);
	const [courses, setCourses] = useState([]);
	const [updatedCourses, setUpdatedCourses] = useState([]);
	const [schedule, setSchedule] = useState([]);
	const [updatedSchedule, setUpdatedSchedule] = useState([]);

	console.log("new group", newGroup);
	console.log("students", students);

	useEffect(() => {
		const studentAttendance = () => {
			if (newGroup.students.length > 0) {
				newGroup.students.forEach((student) => {
					// if newGroup.student contains id from objects of array "students" add docs of every student to groups/groupID/attendance/[docs with date, students with attnedance status]
				});
			}
		};

		studentAttendance();
	}, [newGroup.students]);

	// These are just some basic properties, and depending on your CRM's requirements, you may need to include additional properties such as attendance records, grades, assignments, and more.

	const generateLessonDays = (startDate, endDate) => {
		const lessonDays = [];
		let currentDate = dayjs(startDate);

		while (currentDate.isBefore(dayjs(endDate).add(1, "day"))) {
			console.log("currentDate:", currentDate.format("YYYY-MM-DD"));
			lessonDays.push(currentDate.toDate());
			currentDate = currentDate.add(1, "day");
		}

		console.log("lessonDays:", lessonDays);
		return lessonDays;
	};

	const createGroup = async () => {
		// Upload the data to the database
		if (
			newGroup.group_name !== "" &&
			newGroup.course !== "" &&
			newGroup.start_date !== "" &&
			newGroup.end_date !== "" &&
			newGroup.schedule !== ""
		) {
			try {
				const validGroupData = { ...newGroup };
				Object.keys(validGroupData).forEach((key) => {
					if (validGroupData[key] === undefined) {
						delete validGroupData[key];
					}
				});

				// Add the group document
				const groupDoc = await addDoc(collection(db, "groups"), validGroupData);

				// Create documents for attendance for each lesson day
				const lessonDays = generateLessonDays(newGroup.start_date, newGroup.end_date);
				const attendanceCollectionRef = collection(db, "groups", groupDoc.id, "attendance");

				console.log("lessonDays", lessonDays);

				lessonDays.forEach(async (lessonDay) => {
					const lessonDayAttendance = {
						date: Timestamp.fromDate(lessonDay), // Convert to
					};

					// Add the attendance document for this lesson day
					await addDoc(attendanceCollectionRef, lessonDayAttendance).catch((error) =>
						console.log("Error on attendance collection creation", error)
					);
				});

				setNewGroup({
					group_name: "",
					course: "",
					teacher_id: [],
					start_date: "",
					end_date: "",
					schedule: "",
					students: [],
				});
			} catch (error) {
				console.log(error);
			}
		}
	};

	useEffect(() => {
		const getCourses = async () => {
			const querySnapshot = await getDocs(collection(db, "courses"));
			querySnapshot.forEach((doc) => {
				setCourses((prev) => [...prev, { id: doc.id, ...doc.data() }]);
			});
		};
		getCourses();
	}, []);

	useEffect(() => {
		const updatedCourse = () => {
			const updated = courses.map(({ value, eng, rus, uzb }) => {
				let label;
				if (language === "eng") {
					label = eng;
				} else if (language === "rus") {
					label = rus;
				} else if (language === "uzb") {
					label = uzb;
				}
				return { value, label };
			});
			setUpdatedCourses(updated);
		};
		updatedCourse();
	}, [courses, language]);

	// console.log("courses", updatedCourses);

	useEffect(() => {
		const getUsers = async () => {
			const querySnapshot = await getDocs(collection(db, "users"));
			querySnapshot.forEach((doc) => {
				setUsers((prev) => [...prev, { id: doc.id, ...doc.data() }]);
			});
		};
		getUsers();
	}, []);

	useEffect(() => {
		const loadedUsers = () => {
			users.map((user) => {
				if (user.role === "Teacher") {
					setStaff((prev) => [
						...prev,
						{ value: user.id, label: `${user.firstName} ${user.lastName}`, ...user },
					]);
				} else if (user.role === "Student") {
					setStudents((prev) => [
						...prev,
						{ value: user.id, label: `${user.firstName} ${user.lastName}`, ...user },
					]);
				}
			});
		};
		loadedUsers();
	}, [users]);

	useEffect(() => {
		const getSchedule = async () => {
			const querySnapshot = await getDocs(collection(db, "schedule"));
			querySnapshot.forEach((doc) => {
				setSchedule((prev) => [...prev, { ...doc.data() }]);
			});
		};
		getSchedule();
	}, []);

	useEffect(() => {
		const updatedSchedule = () => {
			const updated = schedule.map(({ value, eng, rus, uzb }) => {
				let label;
				if (language === "eng") {
					label = eng;
				} else if (language === "rus") {
					label = rus;
				} else if (language === "uzb") {
					label = uzb;
				}
				return { value, label };
			});
			setUpdatedSchedule(updated);
		};
		updatedSchedule();
	}, [schedule, language]);

	const onStartDateChange = (value, dateString) => {
		// console.log("Selected Time: ", value);
		// console.log("Formatted Selected Time: ", dateString);
	};
	const onStartDateOk = (value) => {
		// console.log("onOk: ", value.$d);
		setNewGroup((prev) => ({ ...prev, start_date: dayjs(value.$d).toDate() }));
	};

	const onEndDateChange = (value, dateString) => {
		// console.log("Selected Time: ", value);
		// console.log("Formatted Selected Time: ", dateString);
	};
	const onEndDateOk = (value) => {
		// console.log("onOk: ", value.$d);
		setNewGroup((prev) => ({ ...prev, end_date: dayjs(value.$d).toDate() }));
	};

	const disabledDate = (current) => {
		const dayOfWeek = current.day();
		if (newGroup.schedule === "oddDays") return dayOfWeek === 0 || dayOfWeek % 2 !== 0;
		if (newGroup.schedule === "evenDays") return dayOfWeek === 0 || dayOfWeek % 2 === 0;
		if (newGroup.schedule === "everyday") return dayOfWeek === 0;
	};

	const handleStudentSelect = (value) => {
		const selectedValues = value.map((option) => option.value);
		setNewGroup((prev) => ({
			...prev,
			students: selectedValues,
		}));
	};
	const handleTeacherSelect = (value) => {
		const selectedValues = value.map((option) => option.value);
		setNewGroup((prev) => ({
			...prev,
			teacher_id: selectedValues,
		}));
	};

	// console.log(users);
	console.log(newGroup);

	return (
		<div>
			<div>
				<div className="flex flex-col justify-center items-center gap-2 bg-white p-3 rounded-md border border-[#e5e5e5]">
					<h3 className="text-[15px] font-medium ml-[3px] mb-[5px] text-[#fa8c17]">Create group</h3>
					<div className="w-[300px] max-w-[300px]">
						<p className="text-[13px] font-normal">Group name</p>
						<Input
							className="w-full"
							value={newGroup.group_name}
							maxLength={150}
							onChange={(e) => setNewGroup((prev) => ({ ...prev, group_name: e.target.value }))}
						/>
					</div>
					<div className="w-[300px] max-w-[300px]">
						<p className="text-[13px] font-normal">Select course</p>
						<Select
							labelInValue
							style={{
								width: "100%",
							}}
							onChange={(value) => setNewGroup((prev) => ({ ...prev, course: value.key }))}
							options={updatedCourses}
						/>
					</div>
					<div className="w-[300px] max-w-[300px]">
						<p className="text-[13px] font-normal">Select schedule</p>
						<Select
							labelInValue
							style={{
								width: "100%",
							}}
							onChange={(value) => setNewGroup((prev) => ({ ...prev, schedule: value.key }))}
							options={updatedSchedule}
						/>
					</div>
					<div className="w-[300px] max-w-[300px]">
						<p className="text-[13px] font-normal">Select start date</p>
						<DatePicker
							disabled={newGroup.schedule === ""}
							showTime
							onChange={onStartDateChange}
							onOk={onStartDateOk}
							disabledDate={disabledDate}
							className="w-full"
						/>
					</div>
					<div className="w-[300px] max-w-[300px]">
						<p className="text-[13px] font-normal">Select end date</p>
						<DatePicker
							disabled={newGroup.schedule === ""}
							showTime
							onChange={onEndDateChange}
							onOk={onEndDateOk}
							disabledDate={disabledDate}
							className="w-full"
						/>
					</div>
					<div className="w-[300px] max-w-[300px]">
						<p className="text-[13px] font-normal">Select teacher</p>
						<Select
							labelInValue
							mode="multiple"
							style={{
								width: "100%",
							}}
							onChange={(value) => handleTeacherSelect(value)}
							options={staff}
						/>
					</div>
					<div className="w-[300px] max-w-[300px]">
						<p className="text-[13px] font-normal">Select students</p>
						<Select
							labelInValue
							mode="multiple"
							style={{
								width: "100%",
							}}
							onChange={(value) => handleStudentSelect(value)}
							options={students}
						/>
					</div>
					<div className="w-[300px] max-w-[300px] mt-2">
						<button
							onClick={() => createGroup()}
							className="transition-all w-full py-[5px] bg-green-600 hover:bg-[#00b400] text-white text-sm font-medium rounded">
							Create
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Groups;
