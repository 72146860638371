import { useState } from "react";
import "./App.css";
import Header from "./Components/Header";
import { useTranslation } from "react-i18next";
import CourseCard from "./Components/CourseCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as RCarousel } from "react-responsive-carousel";
import { ArrowLeftOutlined, ArrowRightOutlined, LineOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function App() {
	const notify = (message) => toast(message);
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		status: "awaiting",
	});
	const [sending, setSending] = useState(false);
	const [phoneExists, setPhoneExists] = useState(false);
	const [resultsCarousel, setResultsCarousel] = useState({
		current: 1,
		total: 12,
		name: "Ataxanova Yulduz",
		university: "Urganch davlat universiteti Filologiya va tillari o'qitish",
		scholarship: "1-GRANT",
	});
	const [t] = useTranslation("common");

	const handleCustomPrevClick = () => {
		// Handle previous slide logic here
		const nextButton = document.querySelector(".carousel-prev-arrow");
		if (nextButton) {
			nextButton.click();
		}
	};

	const handleCustomNextClick = () => {
		// Handle next slide logic here
		const nextButton = document.querySelector(".carousel-next-arrow");
		if (nextButton) {
			nextButton.click();
		}
	};

	// const onSubmit = async () => {
	// 	setSending(true);

	// 	const submissionsRef = collection(db, "requests");
	// 	const phoneQuery = query(submissionsRef, where("phone", "==", formData.phone));
	// 	const querySnapshot = await getDocs(phoneQuery);

	// 	if (querySnapshot.empty) {
	// 		// Add the form data to the 'requests' collection
	// 		const requestsRef = collection(db, "requests");
	// 		await addDoc(requestsRef, formData).then(() => {
	// 			notify("Thank you for your interest! We will call you back shortly!");
	// 		});
	// 	} else {
	// 		setPhoneExists(true);
	// 		notify("You have already requested with this info, We will call you back as soon as possible!");
	// 	}
	// };

	const results = [
		{
			name: "Egamova Gulshanoy",
			univer:
				"Toshkent davlat o'zbek tili va adabiyoti universiteti O'zbek tili o'zga tilli guruhlarda",
			scholarship: "GRANT",
			pic: require("./assets/gulshanoy.jpg"),
		},
		{
			name: "Ataxanova Yulduz",
			univer: "Urganch davlat universiteti Filologiya va tillari o'qitish",
			scholarship: "1-GRANT",
			pic: require("./assets/yulduz.jpg"),
		},
		{
			name: "Xusainova Dilnura",
			univer: "Urganch davlat universiteti Xorijiy til va adabiyot",
			scholarship: "GRANT",
			pic: require("./assets/dilnura.jpg"),
		},
		{
			name: "Solieva Shoira",
			univer: "O'zbekiston davlat jahon tillari universiteti Rus tili o'zga tilli guruhlarda",
			scholarship: "GRANT",
			pic: require("./assets/shoira.jpg"),
		},
		{
			name: "Xudaybergenova Dilnavoz",
			univer: "Urganch davlat universiteti Xorijiy til va adabiyoti",
			scholarship: "GRANT",
			pic: require("./assets/dilnavoz.jpg"),
		},
		{
			name: "Babajonova Sarvinoz",
			univer: "Urganch davlat universiteti Rus tili o'zga tilli guruhlarda",
			scholarship: "GRANT",
			pic: require("./assets/sarvinoz.jpg"),
		},
		{
			name: "Yo'ldoshov Kurbondurdi",
			univer: "O'zbekiston davlat jahon tillari universiteti Rus tili o'zga tilli guruhlarda",
			scholarship: "GRANT",
			pic: require("./assets/kurbondurdi.jpg"),
		},
		{
			name: "Boboxonova Sevarabonu",
			univer: "Urganch davlat universiteti Xorijiy til va adabiyoti",
			scholarship: "GRANT",
			pic: require("./assets/sevara.jpg"),
		},
		{
			name: "Allazarov Azizbek",
			univer: "Toshkent davlat yuridik universiteti",
			scholarship: "",
			pic: require("./assets/aziz.jpg"),
		},
		{
			name: "Bagibekova Madina",
			univer: "Toshkent davlat yuridik universiteti",
			scholarship: "",
			pic: require("./assets/madina.jpg"),
		},
		{
			name: "Xajiyev Humoyun",
			univer: "Toshkent davlat yuridik universiteti",
			scholarship: "",
			pic: require("./assets/humoyun.jpg"),
		},
		{
			name: "Beyjonova Kumush",
			univer: "Toshkent davlat yuridik universiteti",
			scholarship: "",
			pic: require("./assets/kumush.jpg"),
		},
	];

	const responsive = {
		desktop: {
			breakpoint: {
				max: 3000,
				min: 1280,
			},
			items: 4,
			partialVisibilityGutter: 40,

			breakpoint: {
				max: 1280,
				min: 1024,
			},
			items: 3,
			partialVisibilityGutter: 40,
		},
		tablet: {
			breakpoint: {
				max: 1024,
				min: 464,
			},
			items: 2,
			partialVisibilityGutter: 30,
		},
		mobile: {
			breakpoint: {
				max: 464,
				min: 0,
			},
			items: 1,
			partialVisibilityGutter: 30,
		},
	};

	return (
		<div className="App w-full max-w-[1440px]">
			<Header />
			<div className="flex justify-between md:flex-wrap px-8">
				<div className="flex flex-col items-start justify-center p-8 mt-4 rounded-[1rem] flex-1">
					<h2 className="my-3 text-[#003d65] text-3xl font-bold">{t("header.title")}</h2>
					<p className="text-black max-w-[500px]">{t("header.desc")}</p>
					<a
						href="https://md.leaderonline.uz"
						// onClick={() => navigate("/signin")}
						className="transition-all my-3 bg-[#fe7104] hover:bg-[#ff9346] p-4 text-white rounded-[20px] text-2xl font-medium">
						{t("header.button")}
					</a>
				</div>
				<div className="flex-1 flex justify-center items-center">
					<img
						className="lg:max-w-[300px] max-w-[500px] h-auto object-contain"
						src={require("./assets/header-pic.png")}
						alt="learning lamp"
					/>
				</div>
			</div>
			<div id="courses" className="px-8 sm:px-4 my-12">
				<h3 className="text-left text-3xl font-bold py-3">{t("courses.courses")}</h3>
				{/* <div
					className="py-4 flex gap-4 overflow-x-scroll w-[1400px] 
				courses_container"> */}
				<Carousel
					additionalTransfrom={0}
					arrows
					autoPlaySpeed={3000}
					centerMode={false}
					className="w-full"
					containerClass="container w-full"
					dotListClass=""
					draggable
					focusOnSelect={false}
					infinite={false}
					itemClass=""
					keyBoardControl
					minimumTouchDrag={80}
					pauseOnHover
					renderArrowsWhenDisabled={false}
					renderButtonGroupOutside={false}
					renderDotsOutside={false}
					responsive={responsive}
					rewind={false}
					rewindWithAnimation={false}
					rtl={false}
					shouldResetAutoplay
					showDots={false}
					sliderClass=""
					slidesToSlide={1}
					swipeable>
					<CourseCard
						img={require("./assets/uzbek.png")}
						bg={"bg-[#47e77145]"}
						title={t("courses.uzbek.name")}
						purpose={t("courses.uzbek.purpose")}
						duration={t("courses.uzbek.duration")}
						schedule={t("courses.uzbek.schedule")}
						register={t("courses.apply")}
					/>
					<CourseCard
						img={require("./assets/russian.png")}
						bg={"bg-[#2500ff26]"}
						title={t("courses.russian.name")}
						purpose={t("courses.russian.purpose")}
						duration={t("courses.russian.duration")}
						schedule={t("courses.russian.schedule")}
						register={t("courses.apply")}
					/>
					<CourseCard
						img={require("./assets/english.png")}
						bg={"bg-[#bc173733]"}
						title={t("courses.english.name")}
						purpose={t("courses.english.purpose")}
						duration={t("courses.english.duration")}
						schedule={t("courses.english.schedule")}
						register={t("courses.apply")}
					/>
					<CourseCard
						img={require("./assets/ielts.png")}
						bg={"bg-[#f5433730]"}
						title={t("courses.ielts.name")}
						purpose={t("courses.ielts.purpose")}
						duration={t("courses.ielts.duration")}
						schedule={t("courses.ielts.schedule")}
						register={t("courses.apply")}
					/>
					<CourseCard
						img={require("./assets/rus_oral.png")}
						bg={"bg-[#ffec3b4d]"}
						title={t("courses.russianoral.name")}
						purpose={t("courses.russianoral.purpose")}
						duration={t("courses.russianoral.duration")}
						schedule={t("courses.russianoral.schedule")}
						register={t("courses.apply")}
					/>
					<CourseCard
						img={require("./assets/history.png")}
						bg={"bg-[#673bb730]"}
						title={t("courses.history.name")}
						purpose={t("courses.history.purpose")}
						duration={t("courses.history.duration")}
						schedule={t("courses.history.schedule")}
						register={t("courses.apply")}
					/>
					<CourseCard
						img={require("./assets/math.png")}
						bg={"bg-[#cddd3940]"}
						title={t("courses.math.name")}
						purpose={t("courses.math.purpose")}
						duration={t("courses.math.duration")}
						schedule={t("courses.math.schedule")}
						register={t("courses.apply")}
					/>
					<CourseCard
						img={require("./assets/physics.png")}
						bg={"bg-[#bbdffb87]"}
						title={t("courses.physics.name")}
						purpose={t("courses.physics.purpose")}
						duration={t("courses.physics.duration")}
						schedule={t("courses.physics.schedule")}
						register={t("courses.apply")}
					/>
					<CourseCard
						img={require("./assets/chemistry.png")}
						bg={"bg-[#c8e7c97d]"}
						title={t("courses.chemistry.name")}
						purpose={t("courses.chemistry.purpose")}
						duration={t("courses.chemistry.duration")}
						schedule={t("courses.chemistry.schedule")}
						register={t("courses.apply")}
					/>
					<CourseCard
						img={require("./assets/biology.png")}
						bg={"bg-[#ff572233]"}
						title={t("courses.biology.name")}
						purpose={t("courses.biology.purpose")}
						duration={t("courses.biology.duration")}
						schedule={t("courses.biology.schedule")}
						register={t("courses.apply")}
					/>
					<CourseCard
						img={require("./assets/kids.jpg")}
						bg={"bg-[#ffcf0036]"}
						title={t("courses.kids.name")}
						purpose={t("courses.kids.purpose")}
						duration={t("courses.kids.duration")}
						schedule={t("courses.kids.schedule")}
						register={t("courses.apply")}
					/>
				</Carousel>
				{/* </div> */}
			</div>
			<div id="about" className="flex flex-wrap lg:flex-col px-8 my-8 gap-4">
				<div className="flex-1 relative">
					<img
						className="max-w-[400px] md:max-w-[300px] height-[500px] object-cover rounded-xl"
						src={require("./assets/young-colleagues-working-together-in-cafe-min.jpg")}
						alt=""
					/>
					<img
						className="absolute w-16 top-[10%] right-0 z-[-1]"
						src={require("./assets/yellow-circle.png")}
						alt=""
					/>
					<img
						className="absolute w-28 bottom-[10%] right-0 z-[-1]"
						src={require("./assets/zigzag.png")}
						alt=""
					/>
				</div>
				<div className="flex-1 relative">
					<h4 className="text-[42px] font-bold">{t("about.title")}</h4>
					<p className="text-base font-normal leading-[28px]">{t("about.desc")}</p>
					<img
						className="absolute w-16 top-[-30px] right-0 z-[-1]"
						src={require("./assets/red-triangle.png")}
						alt=""
					/>
					<img
						className="absolute right-[10%] w-16 z-[-1]"
						src={require("./assets/orange-circle.png")}
						alt=""
					/>
				</div>
			</div>
			<div id="advantages" className="py-8">
				<h3 className="text-center text-3xl font-bold">{t("advantages.advantages")}</h3>
				<div className="flex flex-wrap justify-center gap-8 my-8">
					<div className="max-w-[400px] xs:max-w-[350px] p-2 bg-[#dcdcff] flex flex-col items-start rounded-lg shadow">
						<img
							className="w-[100px] h-auto object-contain"
							src={require("./assets/education-quality-monitoring.png")}
							alt="education-quality-monitoring"
						/>
						<div>
							<p className="text-[15px] font-semibold uppercase">{t("advantages.quality.title")}</p>
							<p className="text-sm font-normal">{t("advantages.quality.desc")}</p>
						</div>
					</div>
					<div className="max-w-[400px] xs:max-w-[350px] p-2 bg-[#79c24042] flex flex-col items-start rounded-lg shadow">
						<img
							className="w-[100px] h-auto object-contain"
							src={require("./assets/academic-support.png")}
							alt="education-quality-monitoring"
						/>
						<div>
							<p className="text-[15px] font-semibold uppercase">{t("advantages.support.title")}</p>
							<p className="text-sm font-normal">{t("advantages.support.desc")}</p>
						</div>
					</div>
					<div className="max-w-[400px] xs:max-w-[350px] p-2 bg-[#ff00002b] flex flex-col items-start rounded-lg shadow">
						<img
							className="w-[100px] h-auto object-contain"
							src={require("./assets/innovation.png")}
							alt="education-quality-monitoring"
						/>
						<div>
							<p className="text-[15px] font-semibold uppercase">{t("advantages.innovation.title")}</p>
							<p
								dangerouslySetInnerHTML={{ __html: t("advantages.innovation.desc").replace(/\n/g, "<br>") }}
								className="text-sm font-normal"
							/>
							{/* {t("advantages.innovation.desc")} */}
							{/* </p> */}
						</div>
					</div>
					<div className="max-w-[400px] xs:max-w-[350px] p-2 bg-[#ffd90029] flex flex-col items-start rounded-lg shadow">
						<img
							className="w-[100px] h-auto object-contain"
							src={require("./assets/professionals.png")}
							alt="education-quality-monitoring"
						/>
						<div>
							<p className="text-[15px] font-semibold uppercase">{t("advantages.professionals.title")}</p>
							<p className="text-sm font-normal">{t("advantages.professionals.desc")}</p>
						</div>
					</div>
				</div>
			</div>
			<div id="team" className="px-8 my-8">
				<h3 className="text-center text-3xl font-bold">{t("team.ourteam")}</h3>
				<div className="flex flex-wrap items-start justify-center gap-4 my-8 relative">
					<div className="w-[300px] relative">
						<img
							className="w-full max-h-[400px] object-cover rounded-lg"
							src={require("./assets/umarbek.jpg")}
							alt=""
						/>
						<div className="p-1">
							<p className="text-lg font-bold">Qo'ziboyev Umarbek Ulugbekovich</p>
							<p className="italic">{t("team.umarbek")}</p>
						</div>
						<img
							className="z-[-1] w-[150px] absolute bottom-10 left-[-80px]"
							src={require("./assets/yellow-circle.png")}
							alt=""
						/>
					</div>
					<div className="w-[300px] overflow-hidden ">
						<img
							className="w-full max-h-[400px] object-contain rounded-lg"
							src={require("./assets/ulugbek.jpg")}
							alt=""
						/>
						<div className="p-1">
							<p className="text-lg font-bold">Hajiyev Ulug'bek Qo'ziboyevich</p>
							<p className="italic">{t("team.ulugbek")}</p>
						</div>
					</div>
					<div className="w-[300px] overflow-hidden ">
						<img
							className="w-full max-h-[400px] object-contain rounded-lg"
							src={require("./assets/barno.jpg")}
							alt=""
						/>
						<div className="p-1">
							<p className="text-lg font-bold">Abdullayeva Barno Qodirovna</p>
							<p className="italic">{t("team.barno")}</p>
						</div>
					</div>
					<img
						className="z-[-1] w-20 absolute top-[-10%] left-[10%]"
						src={require("./assets/zigzag.png")}
						alt=""
					/>
					<img
						className="z-[-1] w-20 absolute top-[-5%] right-0"
						src={require("./assets/orange-circle.png")}
						alt=""
					/>
					<img
						className="z-[-1] w-20 absolute bottom-[-5%] right-0"
						src={require("./assets/red-triangle.png")}
						alt=""
					/>
					<img
						className="z-[-1] w-24 absolute bottom-[-52px] left-[50%] rotate-[16deg]"
						src={require("./assets/zigzag.png")}
						alt=""
					/>
				</div>
			</div>

			<div id="results" className="relative px-8 my-20">
				<h3 className="text-center text-3xl font-bold">{t("results.results")}</h3>
				<div className="flex flex-wrap justify-center gap-12 items-end">
					<div className="relative flex flex-wrap max-h-[650px]">
						<RCarousel
							ariaLabel="results"
							axis="horizontal"
							showThumbs={false}
							showIndicators={false}
							showStatus={false}
							autoPlay={true}
							infiniteLoop={true}
							transitionTime={1000}
							interval={4000}
							className="custom_carousel max-w-[500px]"
							showArrows={false}
							renderArrowPrev={(onClickHandler, hasPrev, label) => (
								<button className="carousel-prev-arrow" onClick={onClickHandler}></button>
							)}
							renderArrowNext={(onClickHandler, hasNext, label) => (
								<button className="carousel-next-arrow" onClick={onClickHandler}></button>
							)}
							onChange={(index, item) => {
								setResultsCarousel((prev) => ({
									...prev,
									current: index + 1,
									name: item.props.children[1].props.children,
									university: item.props.children[2].props.children,
									scholarship: item.props.children[3].props.children,
								}));
							}}>
							{results.map((el, index) => (
								<div key={index} className="relative carousel-slide">
									<img className="w-48 object-cover rounded-lg" src={el.pic} />
									<p className="hidden">{el.name}</p>
									<p className="hidden">{el.univer}</p>
									<p className="hidden">{el.scholarship}</p>
								</div>
							))}
						</RCarousel>
						<div className="absolute max-w-[300px] bottom-[50%] right-[-50%] lg:right-0 lg:bottom-0 p-4 rounded-xl bg-[#f8f8f8]">
							<p className="text-xl font-semibold">{resultsCarousel.name}</p>
							<p>{resultsCarousel.university}</p>
							<p>{resultsCarousel.scholarship}</p>
							<img
								className="absolute z-[-1] top-[-80%] right-[-30%] w-72 object-contain"
								src={require("./assets/yellow-circle.png")}
								alt=""
							/>
						</div>
						<img
							className="absolute top-[50%] left-[-25%] rotate-[17deg] w-24 object-contain inline"
							src={require("./assets/zigzag.png")}
							alt=""
						/>
						<img
							className="absolute top-[-15px] left-[-8%] z-[-1] w-24 object-contain"
							src={require("./assets/yellow-circle.png")}
							alt=""
						/>
					</div>
					<div className="flex gap-12">
						<div className="flex items-center gap-2">
							<p>{resultsCarousel.current}</p>
							<LineOutlined />
							<p>{resultsCarousel.total}</p>
						</div>
						<div className="custom-arrows flex gap-3">
							<button
								className="custom-arrow-next flex items-center bg-gray-600 p-3 rounded-full text-white"
								onClick={handleCustomPrevClick}>
								<ArrowLeftOutlined />
							</button>
							<button
								className="custom-arrow-next flex items-center bg-[#ff5900] p-3 rounded-full text-white"
								onClick={handleCustomNextClick}>
								<ArrowRightOutlined />
							</button>
						</div>
					</div>
					<img
						className="absolute bottom-0 left-20 z-[-1] w-16 rotate-45 object-contain"
						src={require("./assets/red-triangle.png")}
						alt=""
					/>
					<img
						className="absolute top-5 right-0 z-[-1] w-24 object-contain"
						src={require("./assets/orange-circle.png")}
						alt=""
					/>
					<img
						className="absolute bottom-5 right-12 z-[-1] w-24 object-contain"
						src={require("./assets/zigzag.png")}
						alt=""
					/>
				</div>
			</div>

			<div id="contacts" className="flex justify-around flex-wrap bg-[#dcdcff] p-8 mt-8 md:gap-4">
				<div className="flex flex-col gap-2">
					<h3 className="text-center text-3xl text-black">{t("footer.contact")}</h3>
					<p className="text-black">
						Email: <span>leaderlearningcentre@gmail.com</span>
					</p>
					<p className="text-black">
						{t("footer.phone")}: <span>+998904303155</span>
					</p>
					<p className="text-black">
						{t("footer.address")}: <span>Urganch sh, P. Mahmud ko'chasi</span>
					</p>
				</div>
				<form
					action="https://formbold.com/s/3qAwn"
					method="POST"
					className="my-3 flex flex-col justify-center items-center gap-2">
					<p className="text-lg xm:text-base font-bold">{t("footer.request")}</p>
					<div className="flex justify-center w-[300px] max-w-full">
						<input
							className="w-full outline-none p-[10px] rounded-xl"
							type="text"
							placeholder={t("footer.first")}
							required
							name="firstname"
							value={formData.firstName}
							onChange={(e) => setFormData((prev) => ({ ...prev, firstName: e.target.value }))}
						/>
					</div>
					<div className="flex justify-center w-[300px] max-w-full">
						<input
							className="w-full outline-none p-[10px] rounded-xl"
							type="text"
							placeholder={t("footer.last")}
							name="lastname"
							value={formData.lastName}
							onChange={(e) => setFormData((prev) => ({ ...prev, lastName: e.target.value }))}
						/>
					</div>
					<div className="flex justify-center w-[300px] max-w-full">
						<input
							className="w-full outline-none p-[10px] rounded-xl"
							type="email"
							placeholder="Email"
							name="email"
							value={formData.email}
							onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
						/>
					</div>
					<div className="flex justify-center w-[300px] max-w-full">
						<input
							className="w-full outline-none p-[10px] rounded-xl"
							type="number"
							placeholder={t("footer.phone")}
							required
							name="phone"
							value={formData.phone}
							onChange={(e) => setFormData((prev) => ({ ...prev, phone: e.target.value }))}
						/>
					</div>
					<div className="flex justify-center w-[300px] max-w-full">
						<button
							type="submit"
							// onClick={() => onSubmit()}
							className="w-full bg-[#004bff] text-white font-semibold p-2 rounded-xl">
							{t("footer.send")}
						</button>
					</div>
				</form>
			</div>
			<div
				id="contacts"
				className="flex items-center justify-between flex-wrap bg-[#dcdcff] p-4 border-t border-black md:flex-col md:gap-4">
				<p className="text-xs font-semibold">© 2022 Leader Learning Centre. All rights reserved.</p>
				<div className="flex gap-4">
					<a href="https://www.facebook.com/leader.learning.centre">
						<img className="w-8 object-contain" src={require("./assets/facebook.png")} alt="" />
					</a>
					<a href="http://instagram.com/leader_learning_centre">
						<img className="w-8 object-contain" src={require("./assets/instagram.png")} alt="" />
					</a>
					<a href="https://t.me/leader_learning_centre">
						<img className="w-8 object-contain" src={require("./assets/telegram.png")} alt="" />
					</a>
				</div>
				<p className="text-xs font-semibold">leaderlearningcentre@gmail.com</p>
			</div>
			<ToastContainer />
		</div>
	);
}

export default App;
