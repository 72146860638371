import React from "react";
import { useTranslation } from "react-i18next";

function CourseCard({ img, bg, title, purpose, duration, schedule, register }) {
	const [t, i18n] = useTranslation("common");

	const scrollToSection = (sectionId) => {
		const element = document.getElementById(sectionId);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};
	return (
		<div
			className={`flex flex-col justify-between shadow-sm ${bg} p-4 min-w-[280px] max-w-[300px] h-full rounded-[10px]`}>
			<div className="flex items-center gap-4">
				<img className="w-[50px] h-auto object-contain rounded-full" src={img} alt="" />
				<h4 className="font-semibold text-2xl">{title}</h4>
			</div>
			<div className="my-3 flex-1 flex flex-col justify-between">
				<div className="flex flex-col gap-1">
					<div className="flex items-start gap-[5px]">
						<p className="text-base">{t("courses.purpose")}:</p>
						<p className="font-medium">{purpose}</p>
					</div>
					<div className="flex items-start gap-[5px]">
						<p className="text-base">{t("courses.duration")}:</p>
						<p className="font-medium">{duration}</p>
					</div>
					<div>
						<p className="font-medium">{schedule}</p>
					</div>
				</div>
				<button
					onClick={() => scrollToSection("contacts")}
					className="bg-[#ff5f00] w-full text-white p-[5px] rounded-[10px] mt-4 font-semibold">
					{register}
				</button>
			</div>
		</div>
	);
}

export default CourseCard;
