import React from "react";
import { formItemLayout, tailFormItemLayout } from "../staticData.js";
import { Button, Input, Cascader, Select, Form } from "antd";
import useUsers from "../Hooks/useUsers.js";

const UserForm = () => {
	const { Option } = Select;
	const [form] = Form.useForm();
	const { onCreateUser } = useUsers();

	return (
		<>
			<Form
				{...formItemLayout}
				form={form}
				name="register"
				onFinish={onCreateUser}
				style={{
					maxWidth: 600,
				}}
				scrollToFirstError>
				<Form.Item
					name="email"
					label="E-mail"
					rules={[
						{
							type: "email",
							message: "The input is not valid E-mail!",
						},
						{
							required: true,
							message: "Please input your E-mail!",
						},
					]}>
					<Input />
				</Form.Item>

				<Form.Item
					name="password"
					label="Password"
					rules={[
						{
							required: true,
							message: "Please input your password!",
						},
					]}
					hasFeedback>
					<Input.Password />
				</Form.Item>

				<Form.Item
					name="confirm"
					label="Confirm Password"
					dependencies={["password"]}
					hasFeedback
					rules={[
						{
							required: true,
							message: "Please confirm your password!",
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue("password") === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error("The two passwords that you entered do not match!"));
							},
						}),
					]}>
					<Input.Password />
				</Form.Item>

				<Form.Item
					name="firstName"
					label="First name"
					rules={[
						{
							required: true,
							message: "Please input user's first name!",
							whitespace: true,
						},
					]}>
					<Input />
				</Form.Item>

				<Form.Item
					name="lastName"
					label="Last name"
					rules={[
						{
							required: true,
							message: "Please input user's last name!",
							whitespace: true,
						},
					]}>
					<Input />
				</Form.Item>

				<Form.Item
					name="fatherName"
					label="Father's name"
					rules={[
						{
							required: true,
							message: "Please input user's name of father!",
							whitespace: true,
						},
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					name="fatherPhone"
					label="Father's phone number"
					rules={[
						{
							required: true,
							message: "Please input user's phone number of father!",
							whitespace: true,
						},
					]}>
					<Input placeholder="+998..." />
				</Form.Item>

				<Form.Item
					name="motherName"
					label="Mother's name"
					rules={[
						{
							required: true,
							message: "Please input phone number!",
							whitespace: true,
						},
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					name="motherPhone"
					label="Mother's phone number"
					rules={[
						{
							required: true,
							message: "Please input phone number!",
							whitespace: true,
						},
					]}>
					<Input placeholder="+998..." />
				</Form.Item>

				<Form.Item
					name="role"
					label="Role"
					rules={[
						{
							type: "array",
							required: true,
							message: "Please select user's role!",
						},
					]}>
					<Cascader
						options={[
							{ value: "Student", label: "Student" },
							{ value: "Teacher", label: "Teacher" },
						]}
					/>
				</Form.Item>

				<Form.Item
					name="phone"
					label="Phone Number"
					rules={[
						{
							required: true,
							message: "Please input user's phone number!",
							whitespace: true,
						},
					]}>
					<Input placeholder="+998..." />
				</Form.Item>

				<Form.Item
					name="gender"
					label="Gender"
					rules={[
						{
							required: true,
							message: "Please select gender!",
						},
					]}>
					<Select placeholder="select your gender">
						<Option value="male">Male</Option>
						<Option value="female">Female</Option>
					</Select>
				</Form.Item>

				<Form.Item
					name="age"
					label="Age"
					rules={[
						{
							required: true,
							message: "Please input user's age!",
							whitespace: true,
						},
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					name="address"
					label="Address"
					rules={[
						{
							required: true,
							message: "Please input user's address!",
							whitespace: true,
						},
					]}>
					<Input />
				</Form.Item>

				<Form.Item {...tailFormItemLayout}>
					<Button style={{ backgroundColor: "#1677ff" }} type="primary" htmlType="submit">
						Register
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default UserForm;
