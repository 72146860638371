import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice.js";
import languageReducer from "../features/languageSlice";

export const store = configureStore({
	reducer: {
		user: userReducer,
		language: languageReducer,
	},
	// middleware: (getDefaultMiddleware) =>
	// 	getDefaultMiddleware({
	// 		serializableCheck: false,
	// 	}),
});
