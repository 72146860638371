import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AudioCard from "../Components/AudioCard";
import { collection, serverTimestamp, Timestamp, onSnapshot } from "firebase/firestore";
import useLoadGroups from "../Hooks/useLoadGroups.js";
import useLoadCourses from "../Hooks/useLoadCourses.js";
import { db } from "../../../app/firebase";
import { InboxOutlined } from "@ant-design/icons";
import { Select, Space, DatePicker } from "antd";
import { PaperClipOutlined, DeleteOutlined, CheckOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import { ToastContainer } from "react-toastify";
import useFiles from "../Hooks/useFiles";
const dateFormat = "DD/MM/YYYY mm:HH";

const Audio = () => {
	const language = useSelector((state) => state.language.data);
	const [addingMaterial, setAddingMaterial] = useState(false);
	const [uploadingData, setUploadingData] = useState({
		course: "",
		groups: [],
		createdAt: serverTimestamp(),
		startDate: "",
		expiryDate: "",
		files: [],
	});
	const groups = useLoadGroups(uploadingData.course);
	const { courses } = useLoadCourses();
	const { handleSend, deleteInputFile, progress, uploaded } = useFiles("audios");
	const [availableFiles, setAvailableFiles] = useState([]);

	const onStartDateOk = (value) => {
		// console.log("onOk: ", value.$d);
		setUploadingData((prev) => ({ ...prev, startDate: Timestamp.fromDate(new Date(value.$d)) }));
	};

	const onDateOk = (value) => {
		// console.log("onOk: ", value);
		setUploadingData((prev) => ({ ...prev, expiryDate: Timestamp.fromDate(new Date(value.$d)) }));
	};

	useEffect(() => {
		const unsubscribeFiles = onSnapshot(collection(db, "audios"), (snapshot) => {
			setAvailableFiles(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
		});

		return () => {
			unsubscribeFiles();
		};
	}, []);

	const handleFileInput = (e) => {
		if (e.target.files) {
			setUploadingData((prev) => ({ ...prev, files: e.target.files }));
		}
	};

	console.log(uploadingData);
	console.log(uploaded);

	return (
		<div className="relative h-full">
			<div className="flex flex-col gap-1 overflow-auto">
				<div>
					<button
						onClick={() => setAddingMaterial(!addingMaterial)}
						className="transition-all bg-white text-[#00a400] border border-[#00bc0080] hover:bg-[#01a500] hover:text-[white] w-full p-3 rounded-md my-3 text-base font-medium">
						UPLOAD AUDIO
					</button>
					{addingMaterial && (
						<div className="flex items-center justify-center bg-white py-2 rounded-md gap-4 border border-[#dedeff]">
							<Space direction="vertical" wrap>
								<div className="flex flex-col gap-3">
									<div>
										<p className="font-medium">Select course</p>
										<Select
											labelInValue
											allowClear
											placeholder="Select course"
											className="min-w-[180px] w-full"
											loading={false}
											onChange={(value) => {
												setUploadingData((prev) => ({ ...prev, course: value.key }));
											}}>
											{courses.map((el) => (
												<Select.Option key={el.value} value={el.eng}></Select.Option>
											))}
										</Select>
									</div>
									<div>
										<p className="font-medium">Select group</p>
										<Select
											allowClear
											mode="multiple"
											maxTagCount={2}
											placeholder="Select group"
											className="min-w-[180px] w-full"
											loading={false}
											onChange={(value) => setUploadingData((prev) => ({ ...prev, groups: value }))}>
											{groups.map((el) => (
												<Select.Option key={el.id} value={el.id}>
													{el.group_name}
												</Select.Option>
											))}
										</Select>
									</div>
									<div className="">
										<p className="font-medium">Select start date</p>

										<DatePicker
											showTime
											// defaultValue={dayjs()}
											disabledDate={(current) => {
												const currentDate = dayjs();
												return current.isBefore(currentDate, "day");
											}}
											onOk={onStartDateOk}
											format={dateFormat}
											className="w-full"
										/>
									</div>
									<div className="">
										<p className="font-medium">Select expiry date</p>

										<DatePicker
											showTime
											disabledDate={(current) => {
												const startDate = dayjs(uploadingData.startDate.seconds * 1000);
												return current.isBefore(startDate, "day");
											}}
											onOk={onDateOk}
											format={dateFormat}
											className="w-full"
										/>
									</div>
								</div>
								<div className="max-w-xs">
									<label
										htmlFor="audio"
										className="rounded-lg cursor-pointer flex flex-col justify-center items-center p-1 h-fit max-w-xs bg-[#e8eefe] overflow-hidden">
										<p className="ant-upload-drag-icon">
											<InboxOutlined className="text-3xl" />
										</p>
										<p className="ant-upload-text">Click or drag file to this area to upload</p>
										<p className="ant-upload-hint">Support for a single or bulk upload.</p>
										<input
											onChange={handleFileInput}
											accept="audio/*"
											multiple
											className="hidden"
											type="file"
											name="audio"
											id="audio"
										/>
									</label>
									<div className="mb-2">
										{Object.values(uploadingData.files).map((item) => {
											let url = URL.createObjectURL(item);
											const foundObj = uploaded.find((el) => el.fileName === item.name);
											return (
												<div
													className={`flex items-center justify-between p-1 gap-2 ${
														uploaded && foundObj && foundObj.progress === 100 ? "bg-[#b2ffb2]" : "bg-[#e8eeff]"
													}  my-1 rounded-lg`}>
													<div className="flex items-center gap-2">
														<PaperClipOutlined />
														<p className="break-all">{item.name}</p>

														{uploaded && foundObj && (
															<CheckOutlined
																className={`${
																	uploaded && foundObj && foundObj.progress === 100 && "text-base text-green-700"
																}`}
															/>
														)}
													</div>
													{foundObj ? (
														<DeleteOutlined
															onClick={() =>
																deleteInputFile(uploadingData.course, item.name, foundObj.id, setUploadingData)
															}
															className="cursor-pointer p-[3px] hover:bg-[#bcceff] rounded-[5px]"
														/>
													) : (
														<DeleteOutlined
															onClick={() =>
																deleteInputFile(uploadingData.course, item.name, null, setUploadingData)
															}
															className="cursor-pointer p-[3px] hover:bg-[#bcceff] rounded-[5px]"
														/>
													)}
												</div>
											);
										})}
									</div>
								</div>
								<button
									className="bg-[#009c3c] text-white w-full p-2 rounded-md"
									onClick={() => handleSend(uploadingData, "Audio saved in database successfully")}>
									Send
								</button>
							</Space>
						</div>
					)}
				</div>
				{courses.map((el) => (
					<div className="flex flex-col min-w-fit bg-white rounded-lg my-1 w-full" key={el.id}>
						<p className="bg-white py-2 px-5 text-[#ff8448] border-b border-b-[#ff8448] rounded-md font-semibold text-base">
							{language === "eng" ? el.eng : language === "rus" ? el.rus : el.uzb}
						</p>
						<div className="flex items-center flex-1 flex-wrap h-full px-2 max-w-full">
							{availableFiles &&
								availableFiles.map((file) => {
									if (file.course === el.value) {
										return (
											<AudioCard
												img={require("../../../assets/file.png")}
												course={file.course}
												title={file.fileName}
												filePath={file.filePath}
												groups={file.groups}
												createdTime={file.createdAt}
												startTime={file.startDate}
												deadline={file.expiryDate}
												lastUpdated={file.updatedAt}
												key={file.id} // Add a unique key
												id={file.id}
											/>
										);
									}
									return null;
								})}
						</div>
					</div>
				))}
			</div>
			<ToastContainer />
		</div>
	);
};

export default Audio;
