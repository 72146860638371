import { auth, db } from "../../../app/firebase.js";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
	doc,
	addDoc,
	updateDoc,
	collection,
	serverTimestamp,
	query,
	where,
	limit,
	onSnapshot,
	orderBy,
	startAfter,
	getDocs,
	getCountFromServer,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

function useUsers() {
	const [users, setUsers] = useState([]);
	const [loadedStudents, setLoadedStudents] = useState([]);
	const [loadedTeachers, setLoadedTeachers] = useState([]);
	const [lastStudent, setLastStudent] = useState(null);
	const [lastTeacher, setLastTeacher] = useState(null);
	const [docsCount, setDocsCount] = useState({ teachers: 0, students: 0 });
	const [loading, setLoading] = useState(false);

	const getCount = async (role) => {
		const q = query(collection(db, "users"), where("role", "==", role));
		// const q = collection(db, "users");

		const snapshot = await getCountFromServer(q);

		role === "Student" && setDocsCount((prev) => ({ ...prev, students: snapshot.data().count }));
		role === "Teacher" && setDocsCount((prev) => ({ ...prev, teachers: snapshot.data().count }));
	};

	useEffect(() => {
		const getDocsNumbers = () => {
			getCount("Student");
			getCount("Teacher");
		};
		getDocsNumbers();
	}, []);

	useEffect(() => {
		const q = query(collection(db, "users"), orderBy("createdAt", "desc"));
		const unsub = onSnapshot(q, (querySnapshot) => {
			setUsers(
				querySnapshot.docs.map((doc) => ({
					...doc.data(),
					key: doc.id,
				}))
			);
		});
		return () => {
			unsub();
		};
	}, []);

	useEffect(() => {
		const divideUsers = () => {
			const students = users.filter((user) => user.role === "Student");
			const teachers = users.filter((user) => user.role === "Teacher");
			setLoadedStudents(students);
			setLoadedTeachers(teachers);
		};
		divideUsers();
	}, [users]);

	// const loadMoreStudents = async () => {
	// 	if (loading) {
	// 		return;
	// 	}

	// 	setLoading(true);

	// 	const next = query(
	// 		collection(db, "users"),
	// 		where("role", "==", "Student"),
	// 		startAfter(lastStudent),
	// 		limit(20)
	// 	);

	// 	const querySnapshot = await getDocs(next);
	// 	setLastStudent(querySnapshot.docs[querySnapshot.docs.length - 1]);

	// 	querySnapshot.forEach((doc) => {
	// 		const data = doc.data();
	// 		setLoadedStudents((prev) => [...prev, { ...data, key: doc.id }]);
	// 	});

	// 	setLoading(false);
	// };

	// const loadMoreTeachers = async () => {
	// 	if (loading) {
	// 		return;
	// 	}

	// 	setLoading(true);

	// 	const next = query(
	// 		collection(db, "users"),
	// 		where("role", "==", "Teacher"),
	// 		// orderBy("createdAt", "desc"),
	// 		startAfter(lastTeacher),
	// 		limit(20)
	// 	);

	// 	const querySnapshot = await getDocs(next);
	// 	setLastTeacher(querySnapshot.docs[querySnapshot.docs.length - 1]);

	// 	querySnapshot.forEach((doc) => {
	// 		const data = doc.data();
	// 		setLoadedTeachers((prev) => [...prev, { ...data, key: doc.id }]);
	// 	});

	// 	setLoading(false);
	// };

	const onCreateUser = async (values) => {
		const notify = (message) => toast(message);
		await createUserWithEmailAndPassword(auth, values.email, values.password)
			.then(async (userCredential) => {
				// Signed in
				// console.log(userCredential.user);
				const mergeArrays = (values) => {
					let res = [];
					values.forEach((elem) => {
						res.push(elem[0]);
					});
					return res;
				};

				await addDoc(collection(db, "users"), {
					email: values.email,
					firstName: values.firstName,
					lastName: values.lastName,
					address: values.address,
					age: values.age,
					course: mergeArrays(values.course),
					role: values.role[0],
					phone: values.phone,
					motherName: values.motherName,
					motherPhone: values.motherPhone,
					fatherName: values.fatherName,
					fatherPhone: values.fatherPhone,
					gender: values.gender,
					timestamp: serverTimestamp(),
				});
			})
			.then(() => {
				return notify("User created");
			})
			.catch((error) => {
				return notify(
					error.message === "auth/email-already-exists" ? "User already exists" : error.message
				);
			});
	};

	const editUserInfo = async (id, data) => {
		const notify = (message) => toast(message);
		const userRef = doc(db, "users", id);
		updateDoc(userRef, data)
			.then((docRef) => {
				console.log(docRef);
				return notify("User updated");
			})
			.catch((error) => {
				return notify(error);
			});
	};

	return {
		onCreateUser,
		editUserInfo,
		loadedStudents,
		loadedTeachers,
		docsCount,
	};
}

export default useUsers;
