import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../app/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { collection, getDocs, query, where } from "firebase/firestore";

const SignIn = () => {
	const notify = (message) => toast(message);
	const [user] = useAuthState(auth);
	const navigate = useNavigate();
	const [authRole, setAuthRole] = useState("");

	const emailLogin = (values) => {
		signInWithEmailAndPassword(auth, values.email, values.password)
			.then((response) => {
				sessionStorage.setItem("Auth token", response._tokenResponse.refreshToken);

				if (authRole === "teacher") {
					const q = query(collection(db, "admins"), where("email", "==", response.user.email));
					getDocs(q)
						.then((querySnapshot) => {
							if (!querySnapshot.empty) {
								// navigate("staff.leaderonline.uz");
								window.location.href = "https://staff.leaderonline.uz";
							}
							notify("No staff account associated with this email");
						})
						.catch((error) => {
							console.log("Error fetching teacher document:", error);
							notify("/dashboard");
						});
				}
				if (authRole === "student") {
					const q = query(collection(db, "users"), where("email", "==", response.user.email));
					getDocs(q)
						.then((querySnapshot) => {
							if (!querySnapshot.empty) {
								// navigate("student.leaderonline.uz");
								window.location.href = "https://student.leaderonline.uz";
							}
							notify("No student account associated with this email");
						})
						.catch((error) => {
							console.log("Error fetching teacher document:", error);
							notify("/dashboard");
						});
				}
			})
			.catch((error) => {
				notify(error.message);
			});
	};

	return (
		<div className="bg-[#0000ff17] w-full h-full flex flex-col">
			<Header />

			{authRole === "" ? (
				<div className="transition-all flex flex-1 flex-col justify-center items-center gap-6">
					<div className="flex items-center flex-col">
						<h1 className="text-[40px] font-bold text-[#000090]">Log In</h1>
						<h5 className="text-xl font-medium text-[#303030]">Choose the profile</h5>
					</div>
					<div className="w-full flex flex-wrap justify-center gap-4 px-5">
						<div
							onClick={() => setAuthRole("student")}
							className="cursor-pointer bg-white hover:bg-[#e6e6ff] transition-all p-2 flex flex-col justify-between items-center gap-4 rounded-[80px] w-[300px] max-w-[300px] max-h-[400px]"
							id="account_selector_shadow">
							<h3 className="text-[25px] text-[#000090] font-medium">Student</h3>
							<img
								className="w-full h-full max-h-[200px] object-contain"
								src={require("../assets/student.png")}
								alt="student png"
							/>
						</div>
						<div
							onClick={() => setAuthRole("teacher")}
							className="cursor-pointer bg-white hover:bg-[#e6e6ff] transition-all p-2 flex flex-col justify-between items-center gap-4 rounded-[80px] w-[300px] max-w-[300px] max-h-[400px]"
							id="account_selector_shadow">
							<h3 className="text-[25px] text-[#000090] font-medium">Teacher</h3>
							<img
								className="w-full h-full max-h-[200px] object-contain"
								src={require("../assets/teacher.png")}
								alt="teacher png"
							/>
						</div>
					</div>
				</div>
			) : (
				<div className="flex-1 flex flex-col justify-center items-center">
					<div className="mb-6">
						<h3 className="text-[32px] font-extrabold text-[#000e57]">Leader Online</h3>
					</div>
					<Form
						name="normal_login"
						className="login-form "
						initialValues={{
							remember: true,
						}}
						onFinish={emailLogin}>
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									message: "Please input your email!",
								},
							]}>
							<Input
								className="py-3"
								prefix={<UserOutlined className="site-form-item-icon" />}
								placeholder="Email"
							/>
						</Form.Item>
						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: "Please input your Password!",
								},
							]}>
							<Input.Password
								className="py-3"
								prefix={<LockOutlined className="site-form-item-icon" />}
								type="password"
								placeholder="Password"
							/>
						</Form.Item>

						<Form.Item>
							<button
								className="bg-[#000e57] text-white w-full py-2 rounded-md text-base font-medium"
								type="submit">
								Log in
							</button>
						</Form.Item>
						<button className="font-semibold text-[#000e58]" onClick={() => setAuthRole("")}>
							Choose other
						</button>
					</Form>
				</div>
			)}
			<ToastContainer />
		</div>
	);
};

export default SignIn;
