import React from "react";

const Courses = () => {
	return (
		<div className="bg-white rounded-sm p-2">
			<div>
				<h3 className="px-2 text-[#0038e1]">Create Course</h3>
				<div></div>
			</div>
			<div></div>
		</div>
	);
};

export default Courses;
