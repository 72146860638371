import React, { useEffect, useState } from "react";
import {
	Timestamp,
	collection,
	deleteDoc,
	doc,
	getDocs,
	query,
	serverTimestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import { DatePicker, Input, Popover, Select } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { db, storage } from "../../../app/firebase";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { deleteObject, getDownloadURL, ref } from "firebase/storage";
import { toast } from "react-toastify";
// import ReactAudioPlayer from "react-audio-player";
// import AudioPlayer from "../../../Components/AudioPlayer";
import { Player } from "react-simple-player";

dayjs.extend(customParseFormat);
const dateFormat = "DD/MM/YYYY mm:HH";

const styles = {
	container: "bg-[#2664ec1c] m-3 min-w-[250px]  flex-1 p-2 rounded-md",
	EditButton:
		"flex flex-1 items-center justify-center gap-1 py-[2px] px-[6px] bg-[#ffedd0] hover:bg-[#ffa314] hover:text-white font-medium rounded-sm border border-[#ffe0b1] text-[#734700] transition-all",
	delButton:
		"flex flex-1 items-center justify-center gap-1 py-[2px] px-[6px] bg-[#ffe5e5] font-medium hover:bg-[#c90008] hover:text-white rounded-sm text-[#a40000] border border-[#ff00001a] transition-all",
};

const LibraryCard = ({
	course,
	id,
	img,
	title,
	filePath,
	groups,
	createdTime,
	startTime,
	deadline,
	lastUpdated,
}) => {
	const notify = (message) => toast(message);
	const [editing, setEditing] = useState(false);
	const [editInfo, setEditInfo] = useState({
		editTitle: title,
		editGroup: groups,
		editStartDate: startTime,
		editDeadline: deadline,
		updatedAt: serverTimestamp(),
	});
	const [courseGroups, setCourseGroups] = useState([]);
	const [docGroups, setDocGroups] = useState([]);
	const [confirm, setConfirm] = useState(false);
	const [audioUrl, setAudioUrl] = useState("");

	const handleConfirm = (newOpen) => {
		setConfirm(newOpen);
	};

	useEffect(() => {
		const getAudioUrl = async () => {
			const fileRef = ref(storage, `audios/${course}/${title}`);

			try {
				const url = await getDownloadURL(fileRef);
				setAudioUrl(url);
			} catch (error) {
				console.log("Error getting download URL:", error);
				setAudioUrl(""); // Return null or handle the error as needed
			}
		};
		getAudioUrl();
	}, [course, title]);

	const handleDownload = (url) => {
		const link = document.createElement("a");
		link.href = url;
		link.download = title;
		link.click();
	};

	const formatDate = (seconds) => {
		if (!seconds) return "";

		const startDate = new Date(seconds * 1000);

		return startDate.toLocaleString("en-GB", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
			hour: "2-digit",
			minute: "2-digit",
		});
	};

	const deleteFile = async (id, filePath) => {
		await deleteDoc(doc(db, "audios", id)).then(async () => {
			const docRef = ref(storage, filePath);

			// Delete the file
			await deleteObject(docRef)
				.then(() => {
					notify("File deleted successfully");
				})
				.catch((error) => {
					console.log("error on seleteFile", error);
					// Uh-oh, an error occurred!
				});
		});
	};

	useEffect(() => {
		const loadGroups = async () => {
			const q = query(collection(db, "groups"), where("course", "==", course));
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				setCourseGroups((prev) => [...prev, { id: doc.id, ...doc.data() }]);
			});
		};
		loadGroups();
	}, []);

	useEffect(() => {
		const getGroupNames = async () => {
			if (courseGroups.length !== 0 && groups) {
				let added = [];
				courseGroups.forEach((item) => {
					if (groups.includes(item.id)) {
						added.push({ id: item.id, name: item.group_name });
					}
				});
				setDocGroups(added);
			}
		};
		getGroupNames();
	}, [groups]);

	const handleSave = async () => {
		await updateDoc(doc(db, "audios", id), {
			fileName: editInfo.editTitle,
			groups: editInfo.editGroup,
			startDate: editInfo.editStartDate,
			expiryDate: editInfo.editDeadline,
			updatedAt: serverTimestamp(),
		}).then(() => {
			setEditing(false);
			notify("File data updated successfully");
		});
	};

	const handleCancel = () => {
		// Reset the edited values
		// setEditInfo({
		// 	editTitle: title,
		// 	editGroup: groups,
		// 	editDeadline: deadline,
		// });

		// Set editing mode to false
		setEditing(false);
	};

	const onDateChange = (value, dateString) => {
		// console.log("Selected Time: ", value);
		// console.log("Formatted Selected Time: ", dateString);
	};
	const onDateOk = (value) => {
		setEditInfo((prev) => ({ ...prev, editDeadline: Timestamp.fromDate(new Date(value.$d)) }));
	};

	const onStartDateOk = (value) => {
		setEditInfo((prev) => ({ ...prev, editStartDate: Timestamp.fromDate(new Date(value.$d)) }));
	};

	// console.log("editInfo", editInfo);

	return (
		<div className={styles.container}>
			<div className=" flex flex-col gap-1 my-2">
				<div>
					{editing ? (
						<Input
							defaultValue={editInfo.editTitle}
							className="w-full"
							value={editInfo.editTitle}
							onChange={(e) => setEditInfo((prev) => ({ ...prev, editTitle: e.target.value }))}
						/>
					) : (
						<div className="flex flex-row justify-between">
							<div className="flex flex-col flex-1 gap-2">
								<div className="flex items-center ">
									<img className="h-4 w-4 object-contain rounded" src={img} alt="doc picture" />
									<button
										onClick={() => handleDownload(audioUrl)}
										className="font-medium break-all text-green-700">
										{title}
									</button>
								</div>
								<div className="flex-1 bg-transparent max-w-[250px]">
									<Player hideVolume src={audioUrl} height={25} />
								</div>
							</div>
							<div className="flex items-center gap-2">
								<EditOutlined
									onClick={() => setEditing(!editing)}
									className="cursor-pointer text-lg leading-none text-white bg-orange-400 rounded-lg p-1"
								/>
								<Popover
									placement="topLeft"
									content={
										<div className="flex flex-row gap-2">
											<button onClick={() => deleteFile(id, filePath)} className={styles.delButton}>
												Delete
											</button>
											<button onClick={() => setConfirm(false)} className={styles.EditButton}>
												Cancel
											</button>
										</div>
									}
									title="Are you sure to delete?"
									trigger="click"
									open={confirm}
									onOpenChange={handleConfirm}>
									<DeleteOutlined
										onClick={() => handleConfirm()}
										className="cursor-pointer text-lg leading-none text-white bg-red-500 rounded-lg p-1"
									/>
								</Popover>
							</div>
						</div>
					)}
				</div>
				<div className="flex gap-1 items-center">
					<img
						className="w-4 object-contain"
						src={require("../../../assets/users.png")}
						alt="users-group"
					/>
					{editing ? (
						<Select
							allowClear
							mode="multiple"
							maxTagCount={2}
							placeholder="Select group"
							className="min-w-[180px] w-full"
							loading={false}
							defaultValue={editInfo.editGroup}
							onChange={(value) => setEditInfo((prev) => ({ ...prev, editGroup: value }))}>
							{courseGroups.map((el) => (
								<Select.Option key={el.id} value={el.id}>
									{el.group_name}
								</Select.Option>
							))}
						</Select>
					) : (
						<div className="flex flex-col">
							{docGroups && docGroups.map((el) => <p className="break-all overflow-wrap">{el.name}</p>)}
						</div>
					)}
				</div>
				<div className="flex gap-1 items-center">
					<img
						className="w-4 object-contain"
						src={require("../../../assets/deadline.png")}
						alt="users-group"
					/>
					{editing ? (
						createdTime && (
							<DatePicker
								showTime
								// onChange={onDateChange}
								defaultValue={dayjs(formatDate(editInfo.editStartDate.seconds), dateFormat)}
								disabledDate={(current) => {
									const createdDate = createdTime.seconds ? dayjs(createdTime.seconds * 1000) : null;
									return createdDate ? current.isBefore(createdDate, "day") : true;
								}}
								format={dateFormat}
								onOk={onStartDateOk}
								className="w-full"
							/>
						)
					) : (
						<p>start from {formatDate(editInfo.editStartDate.seconds)}</p>
					)}
				</div>
				<div className="flex gap-1 items-center">
					<img
						className="w-4 object-contain"
						src={require("../../../assets/deadline.png")}
						alt="users-group"
					/>
					{editing ? (
						editInfo.editStartDate && (
							<DatePicker
								showTime
								// onChange={onDateChange}
								defaultValue={dayjs(formatDate(deadline.seconds), dateFormat)}
								disabledDate={(current) => {
									const startedDate = editInfo.editStartDate
										? dayjs(editInfo.editStartDate.seconds * 1000)
										: null;
									return startedDate ? current.isBefore(startedDate, "day") : true;
								}}
								format={dateFormat}
								onOk={onDateOk}
								className="w-full"
							/>
						)
					) : (
						<p>open till {deadline?.seconds && formatDate(deadline.seconds)}</p>
					)}
				</div>
				<div className="flex gap-1 items-end">
					<p>uploaded on</p>
					<p>{createdTime?.seconds && formatDate(createdTime.seconds)}</p>
				</div>
			</div>
			<div className="flex justify-between gap-2">
				{editing && (
					<>
						<button onClick={() => handleSave(id)} className={styles.EditButton}>
							Save
						</button>
						<button onClick={() => handleCancel()} className={styles.delButton}>
							Cancel
						</button>
					</>
				)}
			</div>
		</div>
	);
};

export default LibraryCard;
