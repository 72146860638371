import React from "react";
import { UserOutlined } from "@ant-design/icons";

const Header = ({ name, email }) => {
	return (
		<div className="flex items-center gap-2">
			<UserOutlined className="text-[30px] bg-[#4d69ff] text-white p-3 rounded-full" />
			<div>
				<p className="text-lg font-semibold">{name}</p>
				<p className="text-[15px] font-normal">{email}</p>
			</div>
		</div>
	);
};

export default Header;
