import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { Dropdown, Space } from "antd";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Header = () => {
	const [t, i18n] = useTranslation("common");
	const currentLanguage = i18n.language;
	const [menuOpen, setMenuOpen] = useState(false);

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
	};

	const scrollToSection = (sectionId) => {
		const element = document.getElementById(sectionId);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	const items = [
		{
			key: "1",
			label: (
				<img
					onClick={() => changeLanguage("en")}
					className="w-5 object-contain"
					src={require("../assets/united-kingdom.png")}
					alt=""
				/>
			),
		},
		{
			key: "2",
			label: (
				<img
					onClick={() => changeLanguage("rus")}
					className="w-5 object-contain"
					src={require("../assets/russia.png")}
					alt=""
				/>
			),
		},
		{
			key: "3",
			label: (
				<img
					onClick={() => changeLanguage("uzb")}
					className="w-5 object-contain"
					src={require("../assets/uzbekistan.png")}
					alt=""
				/>
			),
		},
	];

	return (
		<div className="p-3 flex items-center flex-col gap-3">
			<div className="w-full flex justify-between gap-2">
				<Link to="/">
					<img className="w-44 xm:w-28 h-fit object-contain" src={logo} alt="logo" />
				</Link>
				<div className="text-[#000e59] flex items-center justify-between lg:justify-end lg:gap-5 flex-1 gap-3 font-semibold text-lg">
					<div className="flex items-center gap-3 lg:hidden">
						<button onClick={() => scrollToSection("courses")} className="font-semibold">
							{t("top.courses")}
						</button>
						<button onClick={() => scrollToSection("about")} className="font-semibold">
							{t("top.about")}
						</button>
						<button onClick={() => scrollToSection("advantages")} className="font-semibold">
							{t("top.advantages")}
						</button>
						<button onClick={() => scrollToSection("team")} className="font-semibold">
							{t("top.team")}
						</button>
						<button onClick={() => scrollToSection("results")} className="font-semibold">
							{t("top.results")}
						</button>
						{/* <Link className="" to="/signin">
							E-platform
						</Link> */}
					</div>
					<MenuOutlined onClick={() => setMenuOpen(!menuOpen)} className="min-lg:hidden" />

					<div className="flex items-center gap-3">
						<Dropdown
							menu={{
								items,
							}}>
							<a onClick={(e) => e.preventDefault()}>
								<Space>
									{currentLanguage === "en" && (
										<img
											className="w-12 object-contain"
											src={require("../assets/united-kingdom.png")}
											alt="uk flag"
										/>
									)}
									{currentLanguage === "rus" && (
										<img
											className="w-9 object-contain"
											src={require("../assets/russia.png")}
											alt="rus flag"
										/>
									)}
									{currentLanguage === "uzb" && (
										<img
											className="w-9 object-contain"
											src={require("../assets/uzbekistan.png")}
											alt="uzb flag"
										/>
									)}
									<DownOutlined />
								</Space>
							</a>
						</Dropdown>
						<button
							onClick={() => scrollToSection("contacts")}
							className="bg-[#ff5f00] w-full text-white text-base p-[5px] rounded-md font-normal">
							{t("courses.apply")}
						</button>
					</div>
				</div>
			</div>
			{menuOpen && (
				<div className="transition-all flex items-center flex-col w-full bg-[#f8f8f8] py-4 gap-3 min-lg::hidden">
					<button onClick={() => scrollToSection("courses")} className="font-semibold">
						{t("top.courses")}
					</button>
					<button onClick={() => scrollToSection("about")} className="font-semibold">
						{t("top.about")}
					</button>
					<button onClick={() => scrollToSection("advantages")} className="font-semibold">
						{t("top.advantages")}
					</button>
					<button onClick={() => scrollToSection("team")} className="font-semibold">
						{t("top.team")}
					</button>
					<button onClick={() => scrollToSection("results")} className="font-semibold">
						{t("top.results")}
					</button>
					<Link className="font-semibold" to="/signin">
						E-platform
					</Link>
				</div>
			)}
		</div>
	);
};

export default Header;
