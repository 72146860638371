import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../app/firebase";

function useLoadGroups(course) {
	const [groups, setGroups] = useState([]);

	useEffect(() => {
		const loadGroups = async () => {
			if (course) {
				// Fetch the groups from the database
				const q = query(collection(db, "groups"), where("course", "==", course));
				const querySnapshot = await getDocs(q);
				const newGroups = [];

				// Process the query snapshot and add new groups to the state
				querySnapshot.forEach((doc) => {
					const group = { id: doc.id, ...doc.data() };
					newGroups.push(group);
				});

				setGroups(newGroups);
			}
		};

		loadGroups();
	}, [course]);

	return groups;
}

export default useLoadGroups;
