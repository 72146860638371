import React, { useState } from "react";
import { Tabs, Input, Divider, List, Skeleton, Select, Space } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserForm from "../Components/UserForm";
import UserCard from "../Components/UserCard";
import Courses from "../Components/Courses.js";
import Groups from "../Components/Groups.js";
import useLoadCourses from "../Hooks/useLoadCourses.js";
import useUsers from "../Hooks/useUsers.js";

const Users = () => {
	const { Option } = Select;
	const [searchText, setSearchText] = useState("");
	const { courses } = useLoadCourses();
	const { loadedStudents, loadedTeachers, docsCount } = useUsers();

	console.log("loadedStudents", loadedStudents);
	console.log("loadedTeachers", loadedTeachers);
	console.log(docsCount);

	const handleCourseFilter = (value) => {
		console.log(`selected ${value}`);
	};

	const tabItems = [
		{
			key: "1",
			label: `Students`,
			children: (
				<>
					<div>
						<p>Filter</p>
						<div className="flex flex-wrap gap-2">
							<Input
								className="max-w-xs"
								placeholder="Search by name, phone, address, etc..."
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
							/>
							<Select
								mode="multiple"
								className="min-w-[200px] max-w-full"
								placeholder="Search by course"
								defaultValue={[]}
								onChange={handleCourseFilter}
								optionLabelProp="label">
								{courses.map((el) => (
									<Option key={el.value} value={el.value} label={el.eng}>
										<Space>{el.eng}</Space>
									</Option>
								))}
							</Select>
						</div>
					</div>
					<div id="scrollableDiv" className="h-[600px] max-h-full overflow-auto">
						{loadedStudents.map((item) => (
							<UserCard
								key={item.key}
								id={item.key}
								firstName={item.firstName}
								lastName={item.lastName}
								fatherName={item.fatherName}
								fatherPhone={item.fatherPhone}
								motherName={item.motherName}
								motherPhone={item.motherPhone}
								role={item.role}
								course={item.course}
								phone={item.phone}
								gender={item.gender}
								age={item.age}
								address={item.address}
							/>
						))}
					</div>
				</>
			),
		},
		{
			key: "2",
			label: `Staff`,
			children: (
				<div
					id="scrollableDiv"
					style={{
						height: 600,
						maxHeight: "100%",
						overflow: "auto",
						padding: "0 16px",
					}}>
					{loadedTeachers.map((item) => (
						<UserCard
							key={item.key}
							id={item.key}
							firstName={item.firstName}
							lastName={item.lastName}
							fatherName={item.fatherName}
							fatherPhone={item.fatherPhone}
							motherName={item.motherName}
							motherPhone={item.motherPhone}
							role={item.role}
							course={item.course}
							phone={item.phone}
							gender={item.gender}
							age={item.age}
							address={item.address}
						/>
					))}
				</div>
			),
		},
		{
			key: "3",
			label: `Courses`,
			children: <Courses />,
		},
		{
			key: "4",
			label: `Groups`,
			children: <Groups />,
		},
		{
			key: "5",
			label: `Create User`,
			children: <UserForm />,
		},
	];

	const tabChange = (key) => {
		console.log("Tab key", key);
	};

	return (
		<div>
			<div></div>
			<div>
				<Tabs
					tabBarStyle={{ fontSize: 30, fontWeight: 600 }}
					size="small"
					defaultActiveKey="1"
					items={tabItems}
					onChange={tabChange}
				/>
				<ToastContainer />
			</div>
		</div>
	);
};

export default Users;
