import React, { useState } from "react";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../app/firebase";
import { deleteObject, ref } from "firebase/storage";
import { toast } from "react-toastify";
import Input from "antd/es/input/Input";

const styles = {
	EditButton:
		"flex flex-1 items-center justify-center gap-1 py-[2px] px-[6px] bg-[#ffedd0] hover:bg-[#ffa314] hover:text-white font-medium rounded-sm border border-[#ffe0b1] text-[#734700] transition-all",
	delButton:
		"flex flex-1 items-center justify-center gap-1 py-[2px] px-[6px] bg-[#ffe5e5] font-medium hover:bg-[#c90008] hover:text-white rounded-sm text-[#a40000] border border-[#ff00001a] transition-all",
};

function ProductCard({ id, imgURL, name, price, createdAt }) {
	const notify = (message) => toast(message);
	const [editInfo, setEditInfo] = useState({
		name,
		price,
	});
	const [moreOpen, setMoreOpen] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [editing, setEditing] = useState(false);

	const handleConfirm = (newOpen) => {
		setConfirm(newOpen);
	};

	const handleMoreChange = () => {
		setMoreOpen(!moreOpen);
	};

	const formatDate = (seconds) => {
		if (!seconds) return "";

		const startDate = new Date(seconds * 1000);

		return startDate.toLocaleString("en-GB", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
			hour: "2-digit",
			minute: "2-digit",
		});
	};

	const handleCancel = () => {
		setEditing(!editing);
		setEditInfo({ name, price });
		setMoreOpen(false);
	};

	const handleChange = async (id) => {
		if (!(editInfo.name === "" || editInfo.price === "")) {
			await updateDoc(doc(db, "shop", id), {
				name: editInfo.name,
				price: editInfo.price,
			}).then(() => {
				setEditing(false);
				notify("Item edited");
			});
		} else {
			notify("Input is empty! Enter valid data please!");
		}
	};

	const deleteFile = async (id, filePath) => {
		await deleteDoc(doc(db, "shop", id)).then(async () => {
			const docRef = ref(storage, filePath);

			// Delete the file
			await deleteObject(docRef)
				.then(() => {
					notify("File deleted successfully");
				})
				.catch((error) => {
					console.log("error on seleteFile", error);
					// Uh-oh, an error occurred!
				});
		});
	};

	return (
		<div key={id} className="bg-white shadow-md rounded w-[200px] overflow-hidden">
			<img className="w-full h-[200px] max-h-[200px] object-cover" src={imgURL} alt={name} />
			{editing ? (
				<>
					<div className="w-full p-1">
						<p className="text-xs text-gray-600">Name</p>
						<Input
							defaultValue={name}
							className="w-full"
							value={editInfo.name}
							onChange={(e) => setEditInfo((prev) => ({ ...prev, name: e.target.value }))}
						/>
					</div>
					<div className="w-full p-1">
						<p className="text-xs text-gray-600">Price</p>
						<Input
							type="number"
							defaultValue={price}
							className="w-full"
							value={editInfo.price}
							onChange={(e) => setEditInfo((prev) => ({ ...prev, price: e.target.value }))}
						/>
					</div>
					<div className="w-full p-1 flex gap-1">
						<button
							onClick={() => handleChange(id)}
							className="flex-1 bg-green-600 hover:bg-green-500 text-white rounded-sm py-1">
							Save
						</button>
						<button
							onClick={() => handleCancel()}
							className="flex-1 bg-red-500 hover:bg-red-600 text-white rounded-sm py-1">
							Cancel
						</button>
					</div>
				</>
			) : (
				<>
					<div className="p-2 flex justify-between">
						<p>{name}</p>
					</div>
					<div className="px-2 pb-3">
						<div className="flex gap-2">
							<img className="w-5 object-contain" src={require("../../../assets/coin.png")} alt="coin" />
							<p>{price}</p>
						</div>
						<div className="flex justify-between">
							<p className="text-gray-500 text-[13px] mt-1">{formatDate(createdAt.seconds)}</p>
							<Popover
								placement="topRight"
								content={
									<div className="flex flex-col gap-2">
										<button
											onClick={() => setEditing(!editing)}
											className="text-orange-600 hover:bg-orange-600 hover:text-white rounded-sm">
											Edit
										</button>
										<Popover
											placement="topLeft"
											content={
												<div className="flex flex-row gap-2">
													<button onClick={() => deleteFile(id, `shop/${name}`)} className={styles.delButton}>
														Delete
													</button>
													<button onClick={() => setConfirm(false)} className={styles.EditButton}>
														Cancel
													</button>
												</div>
											}
											title="Are you sure to delete?"
											trigger="click"
											open={confirm}
											onOpenChange={handleConfirm}>
											<button
												onClick={() => handleConfirm()}
												className="transition-all cursor-pointer leading-none text-red-600 hover:bg-red-600 hover:text-white rounded-sm p-1">
												Delete
											</button>
										</Popover>
									</div>
								}
								title=""
								trigger="click"
								open={moreOpen}
								onOpenChange={handleMoreChange}>
								<MoreOutlined className="text-xl font-bold text-black" twoToneColor="#eb2f96" />
							</Popover>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default ProductCard;
