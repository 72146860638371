import React, { useEffect, useState } from "react";
import App from "../App";
import Protected from "../routes/Protected";
import SignIn from "../Pages/SignIn";
import NotFound from "../Pages/NotFound";
import StudentDashboard from "../Pages/Student/StudentDashboard";
import AdminDashboard from "../Pages/Admin/AdminDashboard";
import Settings from "../Pages/Teacher/Settings";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../app/firebase";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../features/userSlice";
import { Space, Spin } from "antd";
import { Route, Routes } from "react-router-dom";
import Library from "../Pages/Student/Pages/Library";
import Audio from "../Pages/Student/Pages/Audio";
import Ranking from "../Pages/Student/Pages/Ranking";
import Video from "../Pages/Student/Pages/Video";
import Shop from "../Pages/Student/Pages/Shop";
import Coins from "../Pages/Student/Pages/Shop";
import Attendance from "../Pages/Student/Pages/Attendance";

const AppRoutes = () => {
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const authUser = useSelector((state) => state.user.user);

	console.log(authUser);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setLoading(false);
			if (user) {
				dispatch(login(user.providerData));
			} else {
				dispatch(logout(null));
			}
		});

		return () => unsubscribe();
	}, [auth]);

	if (loading) {
		return (
			<Space size="large" className="w-full h-full flex items-center justify-center">
				<Spin size="large" />
			</Space>
		);
	}

	return (
		<Routes>
			<Route index element={<App />} />
			<Route path="/signin" element={<SignIn />} />
			<Route
				path="/student"
				element={
					<Protected>
						<StudentDashboard />
					</Protected>
				}
			/>
			<Route
				path="/student/attendance"
				element={
					<Protected>
						<Attendance />
					</Protected>
				}
			/>
			<Route
				path="/student/library"
				element={
					<Protected>
						<Library />
					</Protected>
				}
			/>
			<Route
				path="/student/audio"
				element={
					<Protected>
						<Audio />
					</Protected>
				}
			/>
			<Route
				path="/student/ranking"
				element={
					<Protected>
						<Ranking />
					</Protected>
				}
			/>
			<Route
				path="/student/video"
				element={
					<Protected>
						<Video />
					</Protected>
				}
			/>
			<Route
				path="/student/shop"
				element={
					<Protected>
						<Shop />
					</Protected>
				}
			/>
			<Route
				path="/student/coins"
				element={
					<Protected>
						<Coins />
					</Protected>
				}
			/>
			<Route
				path="/staff"
				element={
					<Protected>
						<AdminDashboard />
					</Protected>
				}
			/>
			<Route
				path="/staff/settings"
				element={
					<Protected>
						<Settings />
					</Protected>
				}
			/>
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

export default AppRoutes;
