import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Approutes from "./routes/AppRoutes";
import { Provider } from "react-redux";
import { store } from "./store/store.js";
import common_en from "./translations/en/common.json";
import common_rus from "./translations/rus/common.json";
import common_uzb from "./translations/uzb/common.json";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

i18next.init({
	interpolation: { escapeValue: false }, // React already does escaping
	lng: "uzb",
	resources: {
		en: {
			common: common_en,
		},
		rus: {
			common: common_rus,
		},
		uzb: {
			common: common_uzb,
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<>
		<Provider store={store}>
			<I18nextProvider i18n={i18next}>
				<BrowserRouter>
					<Approutes />
				</BrowserRouter>
			</I18nextProvider>
		</Provider>
	</>
);

reportWebVitals();
