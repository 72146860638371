import { useState } from "react";
import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from "../../../app/firebase";
import { toast } from "react-toastify";

const useFiles = (collectionName) => {
	const [progress, setProgress] = useState(0);
	const [uploaded, setUploaded] = useState([]);

	const handleSend = async (uploadingData, message) => {
		const notify = (message) => toast(message);
		try {
			for (let i = 0; i < uploadingData.files.length; i++) {
				const file = uploadingData.files[i];
				const storageRef = ref(storage, `${collectionName}/${uploadingData.course}/${file.name}`);
				const uploadTask = uploadBytesResumable(storageRef, file);

				// Track the upload progress
				uploadTask.on(
					"state_changed",
					(snapshot) => {
						// Handle progress changes
						const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
						setProgress(progress);
						if (progress === 100) {
							setUploaded((prev) => [
								...prev,
								{
									fileName: file.name,
									progress: progress,
									id: null,
								},
							]);
						}
					},
					(error) => {
						console.error("Error uploading file:", error);
					},
					async () => {
						// Upload completed
						try {
							const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

							await addDoc(collection(db, collectionName), {
								course: uploadingData.course,
								fileName: file.name,
								filePath: `${collectionName}/${uploadingData.course}/${file.name}`,
								fileURL: downloadURL,
								groups: uploadingData.groups,
								createdAt: uploadingData.createdAt,
								startDate: uploadingData.startDate,
								expiryDate: uploadingData.expiryDate,
							}).then((value) => {
								setUploaded((prev) =>
									prev.map((item) => (item.fileName === file.name ? { ...item, id: value.id } : item))
								);
							});

							notify(message);
						} catch (error) {
							console.error("Error getting download URL:", error);
						}
					}
				);
			}
		} catch (error) {
			console.error("Error handling send:", error);
		}
	};

	const deleteInputFile = async (course, name, id, setUploadingData) => {
		try {
			if (id) {
				await deleteObject(ref(storage, `${collectionName}/${course}/${name}`));
				await deleteDoc(doc(db, collectionName, id));
			}

			setUploadingData((prev) => ({
				...prev,
				files: Object.values(prev.files).filter((file) => file.name !== name),
			}));
			setUploaded((prev) => prev.filter((el) => el.id !== id));
		} catch (error) {
			console.error("Error deleting file:", error);
		}
	};

	const sendToShop = async (uploadingData, message, setUploadingData) => {
		const notify = (message) => toast(message);
		try {
			for (let i = 0; i < uploadingData.files.length; i++) {
				const file = uploadingData.files[i];
				const storageRef = ref(storage, `${collectionName}/${file.name}`);
				const uploadTask = uploadBytesResumable(storageRef, file);

				// Track the upload progress
				uploadTask.on(
					"state_changed",
					(snapshot) => {
						// Handle progress changes
						const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
						setProgress(progress);
						if (progress === 100) {
							setUploaded((prev) => [
								...prev,
								{
									fileName: file.name,
									progress: progress,
									id: null,
								},
							]);
						}
					},
					(error) => {
						console.error("Error uploading file:", error);
					},
					async () => {
						// Upload completed
						try {
							const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

							await addDoc(collection(db, collectionName), {
								name: uploadingData.name,
								price: uploadingData.price,
								fileName: file.name,
								picturePath: `${collectionName}/${file.name}`,
								imgURL: downloadURL,
								createdAt: uploadingData.createdAt,
							}).then((value) => {
								setUploaded((prev) =>
									prev.map((item) => (item.fileName === file.name ? { ...item, id: value.id } : item))
								);
							});

							notify(message);

							setUploadingData({
								name: "",
								price: "",
								createdAt: "",
								files: [],
							});
						} catch (error) {
							console.error("Error getting download URL:", error);
						}
					}
				);
			}
		} catch (error) {
			console.error("Error handling send:", error);
		}
	};

	const deleteShopFile = async (name, id, setUploadingData) => {
		try {
			if (id) {
				await deleteObject(ref(storage, `${collectionName}/${name}`));
				await deleteDoc(doc(db, collectionName, id));
			}

			setUploadingData((prev) => ({
				...prev,
				files: Object.values(prev.files).filter((file) => file.name !== name),
			}));
			setUploaded((prev) => prev.filter((el) => el.id !== id));
		} catch (error) {
			console.error("Error deleting file:", error);
		}
	};

	return { handleSend, deleteInputFile, progress, uploaded, sendToShop, deleteShopFile };
};

export default useFiles;
