import React, { useEffect, useState } from "react";
import Header from "./Components/Header";
import { BellOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Empty, Popover } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../app/firebase";

const StudentDashboard = () => {
	const [openNotifications, setOpenNotifications] = useState(false);
	const [openSettings, setOpenSettings] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [t] = useTranslation("common");

	const [user, setUser] = useState({
		name: "",
		email: "",
	});
	console.log(user);

	const authUser = useSelector((state) => state.user.user);

	useEffect(() => {
		const getUser = async () => {
			try {
				const snapshot = await getDocs(
					query(collection(db, "users"), where("email", "==", authUser[0].email))
				);

				snapshot.forEach((doc) => {
					const data = doc.data();
					setUser({
						name: `${data.firstName} ${data.lastName}`,
						email: data.email,
					});
				});
			} catch (error) {
				console.error("Error fetching user data:", error);
			}
		};
		getUser();
	}, [authUser]);

	const hideNotifications = () => {
		setOpenNotifications(false);
	};

	const handleNotificationChange = (newOpen) => {
		setOpenNotifications(newOpen);
	};

	const hideSettings = () => {
		setOpenSettings(false);
	};

	const handleSettingsChange = (newOpen) => {
		setOpenSettings(newOpen);
	};

	return (
		<div className="h-full flex flex-col items-center bg-gray-100 w-full max-w-[1440px]">
			<div className="flex justify-between p-4 bg-white w-full">
				<Header name={user.name} email={user.email} />
				<div className="flex items-center gap-4">
					<Popover
						placement="bottomLeft"
						content={
							notifications.length > 0 ? (
								<div>Notifications</div>
							) : (
								<Empty
									image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
									imageStyle={{
										height: 80,
										display: "flex",
										justifyContent: "center",
									}}
									description={<span className="font-thin">No message</span>}
								/>
							)
						}
						title="Notifications"
						trigger="click"
						open={openNotifications}
						onOpenChange={handleNotificationChange}>
						<BellOutlined className="text-[25px]" />
					</Popover>
					<Popover
						placement="bottomLeft"
						content={
							<div className="flex gap-3 flex-col">
								<Link to={"/profile"} className="flex items-center px-2 gap-1">
									<UserOutlined className="text-[20px] text-black" />
									<p>My profile</p>
								</Link>
								<div className="flex items-center px-2 gap-1">
									<LogoutOutlined className="text-[20px] text-red-600" />
									<button className="px-2 text-red-600">Sign out</button>
								</div>
							</div>
						}
						title=""
						trigger="click"
						open={openSettings}
						onOpenChange={handleSettingsChange}>
						<SettingOutlined className="text-[25px]" />
					</Popover>
				</div>
			</div>
			<div className="flex flex-wrap gap-4 justify-center max-w-[700px] w-full my-12">
				<Link
					to={"/student/attendance"}
					className="shadow-sm hover:shadow-md flex flex-col justify-between bg-white w-[150px] h-[150px] p-4 rounded-lg">
					<div>
						<img
							className="w-[60px] h-[60px] object-contain rounded-full bg-[#faad14a8] p-[5px]"
							src={require("../../assets/edit-97.png")}
							alt=""
						/>
					</div>
					<div>
						<p className="text-xl font-bold">{t("studentDashboard.attendance")}</p>
					</div>
				</Link>
				<a
					href="https://md.leaderonline.uz"
					className="shadow-sm hover:shadow-md flex flex-col justify-between bg-white w-[150px] h-[150px] p-4 rounded-lg">
					<div>
						<img
							className="w-[60px] h-[60px] object-contain rounded-full bg-[#0000ff42] p-[5px]"
							src={require("../../assets/moodle.png")}
							alt=""
						/>
					</div>
					<div>
						<p className="text-xl font-bold">Moodle</p>
					</div>
				</a>
				<Link
					to={"/student/library"}
					className="shadow-sm hover:shadow-md flex flex-col justify-between bg-white w-[150px] h-[150px] p-4 rounded-lg">
					<div>
						<img
							className="w-[60px] h-[60px] object-contain rounded-full bg-[#0080004f] p-[5px]"
							src={require("../../assets/books.png")}
							alt=""
						/>
					</div>
					<div>
						<p className="text-xl font-bold">{t("studentDashboard.library")}</p>
					</div>
				</Link>
				<Link
					to={"/student/audio"}
					className="shadow-sm hover:shadow-md flex flex-col justify-between bg-white w-[150px] h-[150px] p-4 rounded-lg">
					<div>
						<img
							className="w-[60px] h-[60px] object-contain rounded-full bg-[#ff000059] p-[5px]"
							src={require("../../assets/audio.png")}
							alt=""
						/>
					</div>
					<div>
						<p className="text-xl font-bold">{t("studentDashboard.audio")}</p>
					</div>
				</Link>
				<Link
					to={"/student/ranking"}
					className="shadow-sm hover:shadow-md flex flex-col justify-between bg-white w-[150px] h-[150px] p-4 rounded-lg">
					<div>
						<img
							className="w-[60px] h-[60px] object-contain rounded-full bg-[#006fff40] p-[5px]"
							src={require("../../assets/ranking.png")}
							alt=""
						/>
					</div>
					<div>
						<p className="text-xl font-bold">{t("studentDashboard.ranking")}</p>
					</div>
				</Link>
				<Link
					to={"/student/video"}
					className="shadow-sm hover:shadow-md flex flex-col justify-between bg-white w-[150px] h-[150px] p-4 rounded-lg">
					<div>
						<img
							className="w-[60px] h-[60px] object-contain rounded-full bg-[#8000804a] p-[5px]"
							src={require("../../assets/video.jpg")}
							alt=""
						/>
					</div>
					<div>
						<p className="text-xl font-bold">{t("studentDashboard.video")}</p>
					</div>
				</Link>
				<Link
					to={"/student/shop"}
					className="shadow-sm hover:shadow-md flex flex-col justify-between bg-white w-[150px] h-[150px] p-4 rounded-lg">
					<div>
						<img
							className="w-[60px] h-[60px] object-contain rounded-full bg-[#eb00ff38] p-[5px]"
							src={require("../../assets/basket.png")}
							alt=""
						/>
					</div>
					<div>
						<p className="text-xl font-bold">{t("studentDashboard.shop")}</p>
					</div>
				</Link>
				<Link
					to={"/student/coins"}
					className="shadow-sm hover:shadow-md flex flex-col justify-between bg-white w-[150px] h-[150px] p-4 rounded-lg">
					<div>
						<img
							className="w-[60px] h-[60px] object-contain rounded-full bg-[#faad14a8] p-[5px]"
							src={require("../../assets/exchange.png")}
							alt=""
						/>
					</div>
					<div>
						<p className="text-xl font-bold">{t("studentDashboard.coins")}</p>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default StudentDashboard;
