import "smart-webcomponents-react/source/styles/smart.default.css";
import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import { Timestamp, collection, getDocs, query, where } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { db } from "../../../app/firebase";
import useLoadGroups from "../Hooks/useLoadGroups";
import { Table } from "antd";
import dayjs from "dayjs";
const dateFormat = "DD/MM/YYYY mm:HH";

const columns = [
	{
		title: "STUDENT NAME",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "STATUS",
		dataIndex: "status",
		key: "status",
	},
	{
		title: "PREVIOUS 7 DAYS STATUS",
		dataIndex: "address",
		key: "address",
	},
	{
		title: "ABSENT DAYS",
		dataIndex: "absentDays",
		key: "absentDays",
	},
];
const data = [
	{
		key: 1,
		name: "John Brown",
		age: 32,
		address: "New York No. 1 Lake Park",
		absentDays: 4,
		description: <a>Delete</a>,
	},
];

const Attendance = () => {
	const [filter, setFilter] = useState({
		course: null,
		group_name: null,
		date: new Date(),
	});
	const [filteredGroups, setFilteredGroups] = useState([]);
	const [courses, setCourses] = useState([]);
	const [updatedCourses, setUpdatedCourses] = useState([]);
	const { t, i18n } = useTranslation("common");
	const language = i18n.language;
	const groups = useLoadGroups(filter.course?.value);

	console.log(filteredGroups);
	console.log(filter);

	const fetchFilteredStudents = async () => {
		// if (!filter.course || !filter.group_name || !filter.date) {
		// 	// Return early if any of the filters are not selected
		// 	return;
		// }

		const groupRef = collection(db, "groups");
		const groupQuery = query(
			groupRef,
			where("course", "==", filter.course.value),
			where("group_name", "==", filter.group_name)
		);

		const groupSnapshot = await getDocs(groupQuery);

		setFilteredGroups(groupSnapshot.docs[0].data());

		// if (!groupSnapshot.empty) {
		// 	const groupDoc = groupSnapshot.docs[0];
		// 	const groupData = groupDoc.data();

		// 	// You may need to adjust the Firestore data structure to match your actual structure
		// 	const students = groupData.students || [];

		// 	// Here you can filter students based on the selected date
		// 	// Assuming your Firestore data structure contains attendance records
		// 	// for each student on different dates
		// 	const selectedDate = Timestamp.fromDate(new Date(filter.date.$d));

		// 	// Filter students based on their attendance for the selected date
		// 	const filteredStudents = students.filter((student) => {
		// 		// You need to replace "attendance" with your actual subcollection name
		// 		const attendanceRef = collection(groupRef.doc(groupDoc.id), "attendance");
		// 		const attendanceQuery = query(attendanceRef, where("date", "==", selectedDate));

		// 		// Check if there is an attendance record for the selected date
		// 		return attendanceQuery.size > 0;
		// 	});

		// 	setFilteredGroups(filteredStudents);
		// }
	};

	useEffect(() => {
		fetchFilteredStudents();
	}, [filter]);

	useEffect(() => {
		const setData = () => {};
		setData();
	}, [filter]);

	useEffect(() => {
		const getCourses = async () => {
			const querySnapshot = await getDocs(collection(db, "courses"));
			querySnapshot.forEach((doc) => {
				setCourses((prev) => [...prev, { id: doc.id, ...doc.data() }]);
			});
		};
		getCourses();
	}, []);

	useEffect(() => {
		const updatedCourse = () => {
			const updated = courses.map(({ value, eng, rus, uzb }) => {
				let label;
				if (language === "en") {
					label = eng;
				} else if (language === "rus") {
					label = rus;
				} else if (language === "uzb") {
					label = uzb;
				}
				return { value, label };
			});
			setUpdatedCourses(updated);
		};
		updatedCourse();
	}, [courses, language]);

	// console.log("updatedCourses", updatedCourses);

	const onStartDateOk = (value) => {
		console.log("onOk: ", value.$d);
		setFilter((prev) => ({ ...prev, date: Timestamp.fromDate(new Date(value.$d)) }));
	};

	const onStartDateChange = (value, dateString) => {
		// console.log("Selected Time: ", value);
		setFilter((prev) => ({ ...prev, date: value }));

		// console.log("Formatted Selected Time: ", dateString);
	};

	return (
		<div>
			<div className="flex items-center gap-4 py-2">
				<h3 className="text-[22px] font-semibold">Daily Attendance</h3>
				<div className="flex gap-2">
					<div className="min-w-[150px] max-w-[250px]">
						<Select
							allowClear
							labelInValue
							placeholder={t("courses.course")}
							className="w-full"
							onChange={(value) => setFilter((prev) => ({ ...prev, course: value }))}
							value={filter.course}
							options={updatedCourses}
						/>
					</div>
					<div className="min-w-[150px] max-w-[250px]">
						<Select
							allowClear
							placeholder={t("courses.group")}
							className="w-full"
							loading={false}
							value={filter.group_name}
							onChange={(value) => setFilter((prev) => ({ ...prev, group_name: value }))}>
							{groups.map((el) => (
								<Select.Option key={el.id} value={el.group_name}>
									{el.group_name}
								</Select.Option>
							))}
						</Select>
					</div>
					<DatePicker
						showTime
						// disabledDate={}
						allowClear
						onOk={onStartDateOk}
						onChange={onStartDateChange}
						// value={dayjs(new Date(filter.date))}
						format={dateFormat}
						className="w-full"
					/>
					<button onClick={() => setFilter({ course: null, group_name: null, date: null })}>
						Reset
					</button>
				</div>
			</div>
			<div>
				<Table
					columns={columns}
					expandable={{
						expandedRowRender: (record) => (
							<div
								style={{
									margin: 0,
								}}>
								{record.description}
							</div>
						),
						rowExpandable: (record) => record.name !== "Not Expandable",
					}}
					dataSource={data}
				/>
			</div>
		</div>
	);
};

export default Attendance;
