import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../app/firebase";

function useLoadCourses() {
	const [courses, setCourses] = useState([]);

	useEffect(() => {
		const loadCourses = async () => {
			const querySnapshot = await getDocs(collection(db, "courses"));
			const coursesData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

			setCourses(coursesData);
		};
		loadCourses();
	}, []);

	return { courses };
}

export default useLoadCourses;
