import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import { Button, Input, Popover } from "antd";
import { doc, deleteDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../../../app/firebase";
import { Select, Space } from "antd";
import useUsers from "../Hooks/useUsers";
import useLoadCourses from "../Hooks/useLoadCourses";

const UserCard = ({
	id,
	firstName,
	lastName,
	role,
	course,
	phone,
	age,
	address,
	fatherName,
	fatherPhone,
	motherName,
	motherPhone,
	gender,
}) => {
	const language = useSelector((state) => state.language.data);
	const { Option } = Select;
	const [editing, setEditing] = useState(false);
	const [editUserData, setEditUserData] = useState({
		firstName,
		lastName,
		role,
		course,
		phone,
		age,
		address,
		fatherName,
		fatherPhone,
		motherName,
		motherPhone,
		gender,
	});
	const [open, setOpen] = useState(false);
	const { courses } = useLoadCourses();
	const { editUserInfo } = useUsers();

	const hide = () => {
		setOpen(false);
	};
	const handleOpenChange = (newOpen) => {
		setOpen(newOpen);
	};

	const deleteUser = async (id) => {
		await deleteDoc(doc(db, "users", id));
		setOpen(!open);
	};

	const displayCourses = (coursesList, language) => {
		let courseNames = [];
		coursesList.map((item) => {
			for (const [key, value] of Object.entries(courses)) {
				item === value.value && language === "eng" && courseNames.push(value.eng);
				item === value.value && language === "rus" && courseNames.push(value.rus);
				item === value.value && language === "uzb" && courseNames.push(value.uzb);
			}
		});
		return courseNames;
	};

	return (
		<div className="mt-2 bg-white rounded p-[10px]">
			<div className="flex items-center justify-between gap-3 border-b border-gray-200 pb-2">
				<div className="flex items-center gap-3">
					<UserOutlined className="text-2xl flex items-center" />
					<div className="flex items-center gap-4">
						<h5 className="text-base font-normal">
							{firstName} {lastName}
						</h5>
						{editing && (
							<Input
								defaultValue={firstName}
								className="w-max"
								value={editUserData.firstName}
								maxLength={60}
								onChange={(e) => setEditUserData((prev) => ({ ...prev, firstName: e.target.value }))}
							/>
						)}
						{editing && (
							<Input
								defaultValue={lastName}
								className="w-max"
								value={editUserData.lastName}
								maxLength={60}
								onChange={(e) => setEditUserData((prev) => ({ ...prev, lastName: e.target.value }))}
							/>
						)}
						<p className="bg-[#1677ff] text-white text-center font-semibold py-[2px] px-3 rounded-md">
							{role}
						</p>
					</div>
				</div>
				<div className="flex items-center gap-4">
					<img
						onClick={() => setEditing(!editing)}
						className="w-6 h-6 hover:cursor-pointer"
						src={require("../../../assets/user-edit.png")}
					/>
					<Popover
						content={
							<div>
								<button
									onClick={() => deleteUser(id)}
									className="bg-red-600 text-white py-[3px] px-[10px] rounded-md mr-2"
									type="button">
									Yes
								</button>
								<button
									onClick={hide}
									className="bg-blue-600 text-white py-[3px] px-[10px] rounded-md"
									type="button">
									Cancel
								</button>
							</div>
						}
						title="Are you sure to delete the user?"
						trigger="click"
						open={open}
						onOpenChange={handleOpenChange}>
						<img className="w-6 h-6 hover:cursor-pointer" src={require("../../../assets/delete.png")} />
					</Popover>
				</div>
			</div>
			<div
				className={`flex flex-col ${
					editing && "gap-2"
				} mt-2 pt-2 bg-[#ffe3cf73] p-[5px] rounded transition-all`}>
				<div className="flex gap-2 text-base items-center">
					<p>Phone number:</p>
					<p className="font-medium">{phone}</p>
					{editing && (
						<Input
							className="w-max"
							value={editUserData.phone}
							maxLength={13}
							onChange={(e) => setEditUserData((prev) => ({ ...prev, phone: e.target.value }))}
						/>
					)}
				</div>
				<div className="flex gap-2 text-base items-center">
					<p>Age:</p>
					<p className="font-medium">{age}</p>
					{editing && (
						<Input
							className="w-max"
							value={editUserData.age}
							maxLength={3}
							onChange={(e) => setEditUserData((prev) => ({ ...prev, age: e.target.value }))}
						/>
					)}
				</div>
				<div className="flex gap-2 text-base items-center">
					<p>Gender:</p>
					<p className="font-medium">{gender}</p>
					{editing && (
						<Select defaultValue={gender} placeholder="select gender">
							<Option value="male">Male</Option>
							<Option value="female">Female</Option>
						</Select>
					)}
				</div>
				<div className="flex gap-2 text-base items-center">
					<p>Address:</p>
					<p className="font-medium">{address}</p>
					{editing && (
						<Input
							className="w-max"
							value={editUserData.address}
							maxLength={150}
							onChange={(e) => setEditUserData((prev) => ({ ...prev, address: e.target.value }))}
						/>
					)}
				</div>
				<div className="flex gap-2 text-base items-center">
					<p>Father's name:</p>
					<p className="font-medium">{fatherName}</p>
					{editing && (
						<Input
							className="w-max"
							value={editUserData.fatherName}
							maxLength={60}
							onChange={(e) => setEditUserData((prev) => ({ ...prev, fatherName: e.target.value }))}
						/>
					)}
				</div>
				<div className="flex gap-2 text-base items-center">
					<p>Father's phone:</p>
					<p className="font-medium">{fatherPhone}</p>
					{editing && (
						<Input
							className="w-max"
							value={editUserData.fatherPhone}
							maxLength={13}
							onChange={(e) => setEditUserData((prev) => ({ ...prev, fatherPhone: e.target.value }))}
						/>
					)}
				</div>
				<div className="flex gap-2 text-base items-center">
					<p>Mother's name:</p>
					<p className="font-medium">{motherName}</p>
					{editing && (
						<Input
							className="w-max"
							value={editUserData.motherName}
							maxLength={60}
							onChange={(e) => setEditUserData((prev) => ({ ...prev, motherName: e.target.value }))}
						/>
					)}
				</div>
				<div className="flex gap-2 text-base items-center">
					<p>Mother's phone:</p>
					<p className="font-medium">{motherPhone}</p>
					{editing && (
						<Input
							className="w-max"
							value={editUserData.motherPhone}
							maxLength={13}
							onChange={(e) => setEditUserData((prev) => ({ ...prev, motherPhone: e.target.value }))}
						/>
					)}
				</div>
				{editing && (
					<div className="my-4 mx-2 flex gap-[5px]">
						<button
							onClick={() => editUserInfo(id, editUserData)}
							className="bg-green-600 py-[5px] px-[15px] text-white rounded-md">
							Save
						</button>
						<button
							onClick={() => setEditing(false)}
							className="bg-red-600 py-[5px] px-[15px] text-white rounded-md">
							Cancel
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default UserCard;
