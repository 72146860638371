import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Protected = ({ children }) => {
	const authUser = useSelector((state) => state.user.user);
	const navigate = useNavigate();

	if (!authUser) {
		navigate("/signin");
		return null; // Prevent rendering the component content
	}

	return <div>{children}</div>;
};

export default Protected;
