import { useEffect, useState } from "react";
import whiteLogo from "../../assets/logo.png";
import {
	AppstoreOutlined,
	EditOutlined,
	BookOutlined,
	AudioOutlined,
	BarChartOutlined,
	VideoCameraOutlined,
	ShoppingCartOutlined,
	TransactionOutlined,
	BellOutlined,
	SettingOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Avatar, Space, Badge, Button, Popover, Empty } from "antd";
import { signOut } from "firebase/auth";
import CustomFooter from "../../Components/CustomFooter";
import { Link, useNavigate } from "react-router-dom";
import Attendance from "./Sections/Attendance";
import Audio from "./Sections/Audio";
import Users from "./Sections/Users";
import Overview from "./Sections/Overview";
import Library from "./Sections/Library";
import Ranking from "./Sections/Ranking";
import Video from "./Sections/Video";
import Shop from "./Sections/Shop";
import Coins from "./Sections/Coins";
import { auth, db } from "../../app/firebase";
import { useSelector } from "react-redux";
import { collection, getDocs, query, where } from "firebase/firestore";
import { toast } from "react-toastify";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
	return {
		key,
		icon,
		children,
		label,
	};
}

const items = [
	getItem("Dashboard", "/dashboard", <AppstoreOutlined />),
	getItem("Users", "/dashboard/users", <UserOutlined />),
	getItem("Attendance", "/dashboard/attendance", <EditOutlined />),
	getItem("Library", "/dashboard/library", <BookOutlined />),
	getItem("Audio", "/dashboard/audio", <AudioOutlined />),
	getItem("Ranking", "/dashboard/ranking", <BarChartOutlined />),
	getItem("Video", "/dashboard/video", <VideoCameraOutlined />),
	getItem("Shop", "/dashboard/shop", <ShoppingCartOutlined />),
	getItem("Coins", "/dashboard/coins", <TransactionOutlined />),
];

const AdminDashboard = () => {
	const notify = (message) => toast(message);
	const navigate = useNavigate();
	const [collapsed, setCollapsed] = useState(false);
	const [currentSection, setCurrentSection] = useState(window.location.pathname);
	const {
		token: { colorBgContainer },
	} = theme.useToken();
	const [notification, setNotification] = useState(null);
	const user = useSelector((state) => state.user);
	const [notificationOpen, setNotificationOpen] = useState(false);
	const [settingsOpen, setSettingsOpen] = useState(false);

	useEffect(() => {
		const getUser = async () => {
			const q = query(collection(db, "admins"), where("email", "==", user.email));
			await getDocs(q)
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						navigate("/signin");
					}
				})
				.catch((error) => {
					notify(error.message);
				});
		};
		getUser();
	}, []);

	const hideNotification = () => {
		setNotificationOpen(false);
	};
	const handleNotification = (newOpen) => {
		setNotificationOpen(newOpen);
	};

	const hideSettings = () => {
		setSettingsOpen(false);
	};
	const handleSettings = (newOpen) => {
		setSettingsOpen(newOpen);
	};

	return (
		<Space
			direction="vertical"
			style={{
				width: "100%",
			}}
			size={[0, 48]}>
			<Layout className="min-h-[100vh]">
				<Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
					<div className="h-[65px] border-b border-white">
						<Link to="/">
							<img className="w-full h-full object-contain" src={whiteLogo} alt="logo" />
						</Link>
					</div>
					<Menu
						className="mt-4"
						onClick={(elem) => setCurrentSection(elem.key)}
						theme="dark"
						defaultSelectedKeys={[window.location.pathname]}
						mode="inline"
						items={items}
					/>
				</Sider>
				<Layout>
					<Header
						className="px-4 flex justify-end"
						style={{
							background: colorBgContainer,
						}}>
						<Space size={16} wrap>
							<Popover
								placement="topRight"
								content={
									notification !== null ? (
										<div>notifications</div>
									) : (
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)
								}
								title="Notifications"
								trigger="click"
								open={notificationOpen}
								onOpenChange={handleNotification}>
								<Badge className="cursor-pointer" count={0} color={"orange"} size={"small"}>
									<BellOutlined className="text-xl" />
								</Badge>
							</Popover>

							<Popover
								placement="topRight"
								content={
									<div>
										<div className="flex items-center gap-1 border-b border-gray-300 pb-2">
											<Avatar className="cursor-pointer bg-[#fde3cf] text-[#f56a00]">UQ</Avatar>
											<div className="flex flex-col">
												<p className="w-max text-sm font-semibold">Umarbek Qoziboyev</p>
												<p className="text-xs text-gray-600">leader.learning.centre@mail.ru</p>
											</div>
										</div>
										<div className="mt-2">
											<div className="flex items-center gap-2 border-b border-gray-300">
												<img
													className="w-[20px] object-contain"
													src={require("../../assets/user.png")}
													alt=""
												/>
												<Link to="/dashboard/profile">Profile</Link>
											</div>
											<div className="flex items-center gap-2 border-b border-gray-300 pt-3 pb-1">
												<img
													className="w-[20px] object-contain ml-[3px]"
													src={require("../../assets/setting.png")}
													alt=""
												/>
												<Link to="/dashboard/profile">Settings</Link>
											</div>
											<div className="flex items-center gap-2 border-b border-gray-300 pt-3 pb-1">
												<img
													className="w-[20px] object-contain ml-[3px]"
													src={require("../../assets/logout.png")}
													alt=""
												/>
												<button
													onClick={() => {
														signOut(auth);
														window.location.href = "https://leaderonline.uz";
														navigate("/signin");
													}}
													className="text-red-600">
													Logout
												</button>
											</div>
										</div>
									</div>
								}
								title=""
								trigger="click"
								open={settingsOpen}
								onOpenChange={handleSettings}>
								<Avatar className="cursor-pointer bg-[#fde3cf] text-[#f56a00]">UQ</Avatar>
							</Popover>
						</Space>
					</Header>
					<Content className="mx-4 mt-2">
						{currentSection === "/dashboard" && <Overview />}
						{currentSection === "/dashboard/users" && <Users />}
						{currentSection === "/dashboard/attendance" && <Attendance />}
						{currentSection === "/dashboard/library" && <Library />}
						{currentSection === "/dashboard/audio" && <Audio />}
						{currentSection === "/dashboard/ranking" && <Ranking />}
						{currentSection === "/dashboard/video" && <Video />}
						{currentSection === "/dashboard/shop" && <Shop />}
						{currentSection === "/dashboard/coins" && <Coins />}
					</Content>
					<Footer className="text-center">
						<CustomFooter />
					</Footer>
				</Layout>
			</Layout>
		</Space>
	);
};

export default AdminDashboard;
